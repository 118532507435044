import React from 'react';
import { Modal } from 'reactstrap';
import Confetti from 'react-confetti';
export default function Congratulation({
	openCongratulation,
	setOpenCongratulation,
	bigtext = 'Congratulation',
	description = "You're became our membership",
}) {
	return (
		<Modal
			style={{ margin: '0', maxWidth: '100%' }}
			isOpen={openCongratulation}
			toggle={() => setOpenCongratulation(!openCongratulation)}
			centered={false}
		>
			<Confetti
				style={{ backgroundColor: '#21252991' }}
				width={window.innerWidth}
				height={'1500'}
			/>
			<div id="congratulation" className=" text-center">
				<h1
					className="font-weight-max"
					id="font-congratulation"
					style={{ color: '#fff' }}
				>
					{bigtext}
				</h1>
				<hr
					style={{
						height: '9px',
						backgroundColor: 'white',
						width: '30%',
						borderRadius: '1em',
						marginTop: '0',
						marginBottom: '0',
					}}
				/>
				<h2 className="font-weight-600" style={{ color: '#fff' }}>
					{description}
				</h2>
			</div>
		</Modal>
	);
}
