import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import { isAuthenticated } from 'common';

// reactstrap components
import { Card, CardBody, Col } from 'reactstrap';
import SignIn from 'components/Modal/SignIn';
import Register from 'components/Modal/Register'
import { Helmet } from 'react-helmet';

export default function Login({location}) {
	const [loginState, setLogin] = useState(false);
	const [registerState, setRegister] = useState(false);
	const { from } = location.state || { from: { pathname: '/' } };
	
	useEffect(() => {
		let flag_is_register  = localStorage.getItem('flag_is_register');
		if (flag_is_register) {
			if (flag_is_register == 1) {
				setRegister(!registerState);
			}
			else {
				setLogin(!loginState)
			}
			localStorage.removeItem('flag_is_register');
		}
	})

	if (isAuthenticated()) {
		return <Redirect to={from} />;
	} else {
		return (
			<Col lg="5" md="7">
				<Helmet title="Sign in" />
				<Card className="border-0 fixed-bottom bg-transparent">
					<CardBody className="px-lg-5 py-lg-5">
						<div className="mb-4">
							<p className="text-white h3 mb-0">WELCOME TO</p>
							<h1 className="text-white font-weight-bolder mb-3">
								{process.env.REACT_APP_NAME ||
									'ESCAPE NAIL SPA'}
							</h1>
							<button
								type="button"
								className="btn-primary btn-lg btn-block border border-white"
								onClick={() => setLogin(!loginState)}
							>
								Sign in with Phone Number
							</button>
							<button
								type="button"
								className="btn-secondary btn-lg btn-block border border-white bg-transparent text-white"
								onClick={() => setRegister(!registerState)}
							>
								Register
							</button>
						</div>
						<SignIn loginState={loginState} setLogin={setLogin} />
						<Register
							registerState={registerState}
							setRegister={setRegister}
							setLogin={setLogin}
						/>
					</CardBody>
				</Card>
			</Col>
		);
	}
}
