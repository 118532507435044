import React, { useState, useEffect } from 'react';

// reactstrap components
import { Container } from 'reactstrap';
import { API, getDataCookie } from 'common';
import { Helmet } from 'react-helmet';

export default function AppointmentHistory() {
	const [appointment, setAppointment] = useState(null);
	const [shopDetail, setShopDetail] = useState(null);

	const fecthAppointment = async () => {
		let response = await API.get(
			`customer/getAllAppointment/${getDataCookie(
				process.env.REACT_APP_COOKIE_KEY
			)}`
		);
		let { payload } = response.data;
		if (payload.length > 0) {
			setAppointment(payload);
		}
	};

	const fecthShopDetail = async () => {
		let response = await API.get('shop/detail');
		let { payload } = response.data;
		setShopDetail(payload);
	};

	useEffect(() => {
		fecthAppointment();
		fecthShopDetail();
	}, []);

	return (
		<div className="header mt-4">
			<Helmet title="Appointment History" />
			<Container>
				<div style={{ height: '50px' }}>
					<img
						height={40}
						width={40}
						className="float-left"
						alt="..."
						src={require('assets/img/profile/IC_appointment.png')}
					/>
					<div className="ml-5">
						<h3>Appointment History</h3>
						<span>
							{appointment
								? 'Last appointment on ' +
								  appointment[appointment.length - 1].start_time
								: 'Not available appointment'}
						</span>
					</div>
				</div>
			</Container>
			{appointment && (
				<Container className="mt-5">
					<div id="list-history-appointment">
						{appointment.map((item) => (
							<div
								className="item-history-appointment"
								key={item.id}
							>
								<div className="infor-item">
									<div
										className="float-left"
										style={{ width: '80%' }}
									>
										<h2 style={{ color: '#222222' }}>
											Today,
											<br />
											{item.start_time.replace(
												/^\d{2}\/\d{2}\/\d{4} /,
												''
											) +
												' - ' +
												item.end_time.replace(
													/^\d{2}\/\d{2}\/\d{4} /,
													''
												)}
										</h2>
										<p
											style={{
												fontSize: '17px',
												fontWeight: '600',
											}}
										>
											{item.serviceNames.join(', ')}
										</p>
										<h4>Note</h4>
									</div>
									<div
										className="float-right"
										style={{
											width: '20%',
											textAlign: 'right',
										}}
									>
										<h2 style={{ color: '#0085e7' }}>
											#{item.id}
										</h2>
									</div>
								</div>
								<div className="text-center">
									<img
										width="200"
										alt="QR Code"
										src={item.urlQrCode}
									/>
									<p>Please use this code to checkin</p>
									<p className="text-uppercase h3 text-center font-weight-max text-escape">
										{shopDetail && shopDetail?.companyName}
									</p>
									<p className="text-center text-escape mb-2">
										{`${
											shopDetail && shopDetail?.address
										}, ${shopDetail && shopDetail?.city}, ${
											shopDetail && shopDetail?.state
										} ${shopDetail && shopDetail?.zip}`}
									</p>
									<p className="text-center text-escape mb-0">
										{shopDetail && shopDetail.phone
											? `(${shopDetail.phone
													.slice(2)
													.slice(
														0,
														3
													)}) ${shopDetail.phone
													.slice(2)
													.slice(
														3,
														6
													)} - ${shopDetail.phone
													.slice(2)
													.slice(6)}`
											: ''}
									</p>
								</div>
							</div>
						))}
					</div>
				</Container>
			)}
		</div>
	);
}
