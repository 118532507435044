import React from 'react';
import { FacebookShareButton } from 'react-share';
import {getDataCookie} from 'common'

export default function FacebookShare({
	callbackClose,
	quote = 'test',
	hashtag = '#test'
}) {
	return (
		<FacebookShareButton
			url={`${window.location.origin}/refld=${getDataCookie(process.env.REACT_APP_COOKIE_KEY)}`}
			quote={quote}
			hashtag={hashtag}
			className="btn btn-block btn-primary"
			style={{
				backgroundColor: null,
				border: null,
				padding: null,
				font: null,
				color: null,
				cursor: null,
			}}
		>
			Share on Facebook
		</FacebookShareButton>
	);
}
