import React, { useState, useEffect } from 'react';
import PinInput from 'react-pin-input';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import constant from 'constant';
import { API, setCookieAuthenticate, initHeader } from 'common';
// reactstrap components
import { Button, Modal, CardBody, FormFeedback } from 'reactstrap';
let element;
export default withRouter(function PhoneVerify({
	phoneVerify,
	setPhoneVerify,
	type,
	customerId,
	setLogin,
	history,
	setRegister,
	location,
	resend,
	phone
}) {
	const [pincode, setPinCode] = useState(null);
	const [counter, setCounter] = useState(constant.SETTING_TIME_EXPIRE_CODE);
	const { errors, setError } = useForm();

	const verifyPhone = async () => {
		try {
			if (!pincode) {
				setError('verify', { type: 'length' });
				return false;
			}
			let response = await API.post('customer/confirmOTP', {
				payload: { customerId, verifyCode: pincode },
			});
			if (response.status === 200) {
				// if (type == 'login') {
				setCookieAuthenticate(
					customerId,
					parseInt(process.env.REACT_APP_EXPIRES_COOKIE)
				);
				initHeader();
				response.data.payload.phone = phone;
				localStorage.setItem(
					'infor_customer',
					JSON.stringify(response.data.payload)
				);
				setPhoneVerify(!phoneVerify);
				const { from } = location.state || {
					from: { pathname: '/' },
				};
				history.push(from);
				let congratulation_flag = false;
				if (type !== 'login') {
					congratulation_flag = true;
				}
				localStorage.setItem(
					'congratulation_flag',
					congratulation_flag
				);
				// } else {
				// 	setPhoneVerify(!phoneVerify);
				// 	setLogin(true);
				// }
			}
		} catch (error) {
			const { data } = error.response;
			data.code === 1003 && setError('verify', { type: 'verify_fail' });
			element.clear();
		}
	};

	useEffect(() => {
		let timer;
		if (phoneVerify) {
			timer =
				counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		}
		return () => timer && clearInterval(timer);
	}, [counter, phoneVerify]);
	return (
		<Modal
			className="fixed-bottom"
			size="lg"
			isOpen={phoneVerify}
			toggle={() => setPhoneVerify(!phoneVerify)}
		>
			<CardBody>
				<h2>Phone Verification</h2>
				<p>
					Verification code has been sent to your phone. Enter the
					code to sign-in
				</p>
				<p className="text-center mb-1">
					{counter === 0
						? 'Your code has expired'
						: `Your code will expire after ${counter} seconds`}
				</p>
				<PinInput
					length={4}
					type="numeric"
					ref={(n) => (element = n)}
					inputMode="number"
					style={{ padding: '10px', textAlign: 'center', display: 'flex', justifyContent: 'space-between' }}
					inputStyle={{fontSize: '32px', fontWeight: 'bolder' , borderColor: '#8898aa', width: '60px', height: '60px'}}
					onComplete={(value) => setPinCode(value)}
					autoSelect={true}
				/>
				{errors.verify?.type === 'verify_fail' && (
					<FormFeedback className="text-center mb-1">
						Your verification code is incorrect
					</FormFeedback>
				)}
				{errors.verify?.type === 'length' && (
					<FormFeedback className="text-center mb-1">
						Your verification code must be 4 characters long
					</FormFeedback>
				)}
				<p className="text-center">
					Don't get a verification code?{' '}
					{counter === 0 && (
						<span
							className="text-primary"
							onClick={() => {
								setCounter(constant.SETTING_TIME_EXPIRE_CODE);
								resend();
							}}
						>
							Resend
						</span>
					)}
				</p>
				<div className="text-center mb-3">
					<Button
						className="btn btn-block btn-effect-click"
						color="primary"
						type="button"
						onClick={verifyPhone}
					>
						{type === 'login' ? 'Sign in' : 'Verification'}
					</Button>
				</div>
				<p className="text-center">
					Incorret phone number?{' '}
					<a
						onClick={() => {
							let flag_register = type === 'login' ? 0 : 1;
							localStorage.setItem('flag_is_register', flag_register);
							window.location.href = "/login"
						}}
					>
						Try back
					</a>
				</p>
			</CardBody>
		</Modal>
	);
});
