import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';

export default function SpinFail({ spinFail, setSpinFail }) {
	return (
		<Modal
			isOpen={spinFail}
			toggle={() => setSpinFail(!spinFail)}
			centered={true}
		>
			<ModalBody>
				<div className="text-center">
					<img
						src={require('assets/svg/spinfail.svg')}
						alt="spin fail"
					/>
				</div>
				<p className="text-center mt-4">
					Unfortunately, You lost your turn or didn't win the prize.
					Please try again.
				</p>
				<Button
					color="primary"
					size="lg"
					block
					onClick={() => setSpinFail(!spinFail)}
				>
					Spin Again
				</Button>
			</ModalBody>
		</Modal>
	);
}
