import React from 'react';
import { Link } from 'react-router-dom';

export default function MenuBottom({typeTab}) {
	var default_color = '#8898aa';
	var img_active = "_inactive";
	var luckySpin = {color: default_color, img: img_active};
	var huntSpin = {color: default_color, img: img_active};
	var referralHistory = {color: default_color, img: img_active};

	switch (typeTab) {
		case 'luckyspin':
			luckySpin.color = '#0085e7';
			luckySpin.img = '_active';
			break;
		case 'huntspin':
			huntSpin.color = '#0085e7';
			huntSpin.img = '_active';
			break;
		case 'referralhistory':
			referralHistory.color = '#0085e7';
			referralHistory.img = '_active';
			break;
	}
	
	return (
		<div id="menu-lucky-spin">
			<div className="item-lucky-spin">
				<Link to="/luckyspin" style={{color:`${luckySpin.color}`}}>
					<img
						className="img-responsive"
						alt="Top up"
						src={require(`assets/img/spin/IC_Spin${luckySpin.img}.svg`)}
					/>
					<br/>
					Lucky Spin
				</Link>
			</div>
			<div className="item-lucky-spin">
				<Link to="/huntspin" style={{color:`${huntSpin.color}`}}>
					<img
						className="img-responsive"
						alt="Top up"
						src={require(`assets/img/spin/IC_Hint${huntSpin.img}.svg`)}
					/>
					<br/>
					Hunt Spin
				</Link>
			</div>
			<div className="item-lucky-spin">
				<Link to="/referralhistory" style={{color:`${referralHistory.color}`}}>
					<img
						className="img-responsive"
						alt="Top up"
						src={require(`assets/img/spin/IC_Referral${referralHistory.img}.svg`)}
					/>
					<br/>
					Referral History
				</Link>
			</div>
		</div>
	);
}
