import React, { Component } from 'react';

// reactstrap components
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Button } from 'reactstrap';
import { API, getDataCookie } from 'common';
import SpinFail from 'components/Modal/SpinFail';
import SpinSuccess from 'components/Modal/SpinSuccess';
import MenuBottom from 'components/Common/MenuBottom';

class LuckySpin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			spinFail: false,
			spinSuccess: false,
			disableSpin: false,
			numberSpin: 0,
			resultSpin: {},
			bonus: 0,
		};
	}

	setSpinFail = () => this.setState({ spinFail: !this.state.spinFail });

	setSpinSuccess = () =>
		this.setState({ spinSuccess: !this.state.spinSuccess });

	setDisableSpin = () =>
		this.setState({ disableSpin: !this.state.disableSpin });

	resultSpin = async (type = '') => {
		const response = await API.get(
			`customer/getRandomLuckySpin/${getDataCookie(
				process.env.REACT_APP_COOKIE_KEY
			)}`
		);
		let { payload } = response.data;
		if (payload.length !== 0) {
			this.setState({ resultSpin: payload });
			if (type == 'get_list') {
				this.getListItemSpin(payload.itemSpins);
				this.getNumberSpin(payload.totalSpin > 0 ? payload.totalSpin : 0);
			}
		}
	};

	updateAmount = async (amount) => {
		var data = {};
		try {
			data.customerId = getDataCookie(process.env.REACT_APP_COOKIE_KEY);
			data.amount = amount;
			data.isResult = true;
			// console.log('data', data);
			const response = await API.put('customer/updateLuckySpin', {
				payload: data,
			});
			let { code } = response.data;
			if (code === 200) {
				let infor_customer = JSON.parse(localStorage.getItem('infor_customer'));
				infor_customer.balance += parseInt(data.amount);
				localStorage.setItem(
					'infor_customer',
					JSON.stringify(infor_customer)
				);
				// console.log(response.data);
			}
		} catch (error) {
			// console.log(error);
		}
	};

	getListItemSpin = (data) => {
		var listItemSpin = [];
		data.sort(function (a, b) {
			return a.id - b.id;
		});

		for (var i = 0; i < data.length; i++) {
			listItemSpin.push(data[i].value);
		}

		var color = ['#0085e7', '#08a763', '#ef9b01', '#f5365c', '#bebebe', '#e12fac'];
		var label = listItemSpin;
		var stopAngel = []; // stop angels starting from label index 1(0...label.length)
		var slices = color.length;
		var sliceDeg = 360 / slices;
		var deg = 0;
		var ctx = document.getElementById('canvas').getContext('2d');
		var width = document.getElementById('canvas').width; // size
		var center = width / 2; // center

		function oddEven(num) {
			return num % 2 ? true : false;
		}

		function deg2rad(deg) {
			return (deg * Math.PI) / 180;
		}

		function drawSlice(index, deg, color) {
			var sAngel;
			var current = index <= 0 ? deg : stopAngel[index - 1];
			if (oddEven(index)) {
				if (current <= 0) {
					sAngel = Math.abs(Math.floor(260 + sliceDeg + 10));
				} else {
					sAngel = Math.abs(Math.floor(current - sliceDeg + 10));
				}
				current = sAngel;
				stopAngel.push(current);
			} else {
				if (current <= 0) {
					sAngel = Math.abs(Math.floor(260 + sliceDeg - 10));
				} else {
					sAngel = Math.abs(Math.floor(current - sliceDeg - 10));
				}
				current = sAngel;

				stopAngel.push(current);
			}
			ctx.beginPath();
			// console.log(ctx);
			ctx.fillStyle = color;
			ctx.moveTo(center, center);
			ctx.arc(
				center,
				center,
				center,
				deg2rad(deg),
				deg2rad(deg + sliceDeg),
				false
			);
			ctx.lineTo(center, center);
			ctx.fill();
		}
		function drawText(deg, text) {
			ctx.save();
			ctx.translate(center, center);
			ctx.rotate(deg2rad(deg));
			ctx.textAlign = 'right';
			ctx.fillStyle = '#fff';
			ctx.font = 'bolder 24px Open Sans, sans-serif';
			ctx.fillText('$' + text, 90, 10);
			ctx.restore();
		}

		function drawImg() {
			ctx.clearRect(0, 0, width, width);
			for (let i = 0; i < slices; i++) {
				drawSlice(i, deg, color[i]);
				drawText(deg + sliceDeg / 2, label[i]);
				deg += sliceDeg;
			}
		}

		this.start = () => {
			this.setDisableSpin();
			this.setState({ numberSpin: this.state.numberSpin - 1 });
			this.resultSpin();
			var ele = document.getElementById('canvas');
			ele.classList.add('spin-wheel');
			setTimeout(
				function () {
					ele.classList.remove('spin-wheel');
					let { resultSpin } = this.state;

					let index = resultSpin.resultId;
					if (resultSpin.resultId == 6) {
						index = 0;
					}

					deg = stopAngel[index];
					// var ai = Math.floor(((360 - deg - 90) % 360) / sliceDeg); // deg 2 Array Index
					// ai = (slices + ai) % slices; // Fix negative index
					// console.log('You got:\n' + label[ai]);

					drawImg();

					this.getNumberSpin(this.state.numberSpin);
					if (!resultSpin.isScore) {
						this.setSpinFail();
					}

					for (var i = 0; i < resultSpin.itemSpins.length; i++) {
						if (resultSpin.resultId == resultSpin.itemSpins[i].id) {
							this.updateAmount(resultSpin.itemSpins[i].value);
							if (resultSpin.isScore) {
								this.setState({
									bonus: resultSpin.itemSpins[i].value,
								});
								this.setSpinSuccess();
							}
						}
					}
				}.bind(this),
				3000
			);
		};
		drawImg();
	};

	getNumberSpin = (number_spin) =>
		this.setState({
			disableSpin: number_spin <= 0,
			numberSpin: number_spin,
		});

	componentDidMount() {
		this.resultSpin('get_list');
	}

	back = () => {
		window.history.back();
	}

	render() {
		var { numberSpin } = this.state;
		//
		return (
			<>
				<div id="bg-lucky-spin" style={{overflowY:'scroll'}}>
					<Helmet title="Lucky Spin" />
					<div className="clearfix">
						<Link to="/">
							<img
								className="float-left mt-4 ml-2"
								src={require('assets/img/icons/IC_Back_white.png')}
								width="30"
								alt="Go Back"
							/>
						</Link>
					</div>
					<Container>
						<img width="100%" src={require('assets/img/spin/Logo@3x.png')} alt="lucky" />
						<div className="text-center">
							<div className="wheel mb-6">
								<div id="bg-wheel">
									<canvas
										className=""
										id="canvas"
										width="300"
										height="300"
										style={{marginTop: '23px'}}
									></canvas>
								</div>
								<div className="d-flex justify-content-around align-items-center mt-7" style={{flexDirection:'column'}}>
									<Button
										disabled={this.state.disableSpin}
										color=""
										block
										style={{ maxWidth: '250px', marginBottom: '10px', backgroundColor: '#f5b30b', color:'#fff'}}
										onClick={() => this.start()}
										className="mr-0"
									>
										Tap to Spin
										<br />
										<small>You have {numberSpin} spins left</small>
									</Button>
								</div>
							</div>
						</div>
					</Container>
					<SpinFail
						spinFail={this.state.spinFail}
						setSpinFail={this.setSpinFail}
					/>
					<SpinSuccess
						bonus={this.state.bonus}
						spinSuccess={this.state.spinSuccess}
						setSpinSuccess={this.setSpinSuccess}
					/>
				</div>
				
				<div className="d-flex justify-content-around align-items-center fixed-bottom" style={{flexDirection:'column'}}>
					{/* <Link to="/addcredit" className="p-2 rounded-circle bg-white">
						<img
							alt="Top up"
							src={require('assets/img/home/top_up.svg')}
						/>
					</Link> */}
					<MenuBottom typeTab="luckyspin"/>
				</div>
			</>
		);
	}
}
export default LuckySpin;
