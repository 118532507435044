import React from 'react';
import ReactDOM from 'react-dom';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom';
import { isAuthenticated } from 'common';

// Library
import 'assets/css/slick.min.css'
import 'assets/css/slick-theme.min.css'
import 'assets/scss/argon-dashboard-react.scss';
import 'assets/css/font-awesome.min.css'

// Layout display
import AdminLayout from 'layouts/Admin.jsx';
import AuthLayout from 'layouts/Auth.jsx';
import { ErrorBoundary } from 'react-error-boundary';
import ShowGiftCardDetail from 'views/ShowGiftCardDetail';
import PhoneVerifyCustomer from 'views/PhoneVerifyCustomer';
import Guest from 'views/Guest.jsx';
function ErrorFallback({ error }) {
	return (
		<div color="danger">
			<p>Something went wrong:</p>
			<pre>{error.message}</pre>
		</div>
	);
}

ReactDOM.render(
	<ErrorBoundary FallbackComponent={ErrorFallback}>
	<Router>
		<Switch>
			{/* detail gift card */}
			<Route
				path="/:api_key/gift-card/:id"
				component={ShowGiftCardDetail}
			/>
			{/* to phone verify */}
			<Route
				path="/:api_key/customer/:id"
				component={PhoneVerifyCustomer}
			/>
			<Route
				path="/guest/:api_key"
				component={Guest}
			/>
			<Route
				path="/login"
				render={(props) => <AuthLayout {...props} />}
			/>
			<Route
				path="/"
				render={(props) =>
					isAuthenticated() ? (
						<AdminLayout {...props} />
					) : (
						<Redirect
							to={{
								pathname: '/login',
								state: { from: props.location },
							}}
						/>
					)
				}
			/>
		</Switch>
	</Router></ErrorBoundary>,
	document.getElementById('root')
);
