import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { API, formatTime } from 'common';

export default function CouponDetail({ couponId, openCoupon, setOpenCoupon }) {
	const [coupon, setCoupon] = useState(null);

	useEffect(() => {
		const fetchDetail = async () => {
			let response = await API.get(
				`coupon/getCouponByCustomer/${couponId}`
			);
			let { payload } = response.data;
			setCoupon(payload);
		};
		if (couponId && openCoupon) {
			fetchDetail();
		}
		if (!openCoupon) {
			setCoupon(null);
		}
	}, [couponId, openCoupon]);
	return (
		<Modal
			isOpen={openCoupon}
			toggle={() => setOpenCoupon(!openCoupon)}
			centered={true}
		>
			<div className="clearfix">
				<img
					className="float-left mt-3 ml-3"
					src={require('assets/img/icons/IC_Back.png')}
					width="20"
					alt="Go Back"
					onClick={() => setOpenCoupon(!openCoupon)}
				/>
			</div>
			{coupon && (
				<ModalBody>
					<h1 className="text-center font-weight-max text-escape mb-0">
						{coupon && coupon?.couponName}
					</h1>
					<p className="text-center mb-1">
						<small>Please take a look you</small>
					</p>
					<hr className="m-0" />
					<p className="mt-2 mb-2">
						<small className="text-muted">Date and Time:</small>
						<span className="float-right font-weight-bold">
							{coupon &&
								formatTime(coupon.expireDate, true, 'E, i LLL')}
						</span>
					</p>
					<p>
						<small className="text-muted">Service:</small>
						<span className="float-right font-weight-bold">
							{coupon && coupon?.serviceNames}
						</span>
					</p>

					<hr
						style={{ borderTop: '1px dashed #333' }}
						className="m-0"
					/>
					<div className="text-center">
						<img alt="QR Code" src={coupon && coupon.qrCodeImage} />
					</div>
					<p className="text-center">
						<small>Please use this code to checkin</small>
					</p>
					<p className="text-uppercase h3 text-center font-weight-max text-escape">
						{coupon && coupon.shopDetail?.shopName}
					</p>
					<p className="text-center">
						{`${coupon?.shopDetail?.address}, ${coupon?.shopDetail?.city}, ${coupon?.shopDetail?.state} ${coupon?.shopDetail?.zipCode}`}
					</p>
				</ModalBody>
			)}
		</Modal>
	);
}
