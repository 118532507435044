import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
// reactstrap components
import { Card, Container, Row, Col } from 'reactstrap';

import Slider from 'react-slick';
import { API, setHeader } from 'common';
import AuthenticateGuest from 'components/Modal/AuthenticateGuest';
export default function Guest() {
	const [listBanner, setListBanner] = useState([]);
	const [openAuthenticate, setOpenAuthenticate] = useState(false);

	// get params
	const { api_key } = useParams();

	// banner
	const fecthBanner = async () => {
		const response = await API.get(`shop/getAllBannerByShop`);
		let { payload } = response.data;
		if (payload.length !== 0) {
			setListBanner(payload);
		}
	};

	useEffect(() => {
		setHeader(api_key);
		fecthBanner();
	}, []);

	const settings_slide_announcement = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false
	};

	const banner = listBanner.map((item) => {
		if (item.type == 1) {
			return (
				<img
					key={item.id}
					src={item.urlImage}
					alt={'banner_' + item.id}
					style={{ width: '100%' }}
					className="rounded"
				/>
			);
		} else {
			return (
				<iframe
					key={item.id}
					height="250"
					src={item.urlImage}
					frameborder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
				></iframe>
			);
		}
	});
	return (
		<>
			<Helmet title="Home" />
			<div className="bg-header-home">
				<Container>
					<Row style={{ paddingTop: '20px' }}>
						<div className="col-6">Welcome back,</div>
						<div className="col-6 text-right">
							<img
								height={40}
								width={40}
								className="rounded-circle mr-1"
								alt="..."
								src={
									'/static/media/team-4-800x800.23007132.jpg'
								}
							/>
						</div>
					</Row>
					<Row className="text-center" style={{ paddingTop: '20px' }}>
						<Col>
							<a
								className="text-white"
								onClick={() => {
									setOpenAuthenticate(true);
								}}
							>
								<div className="menu-home">
									<img
										alt="..."
										src={require('assets/img/home/lucky_spin.svg')}
									/>
								</div>
								<br />
								Lucky Spin
							</a>
						</Col>
						<Col>
							<a
								className="text-white"
								onClick={() => {
									setOpenAuthenticate(true);
								}}
							>
								<div className="menu-home">
									<img
										alt="..."
										src={require('assets/img/home/coupon.svg')}
									/>
								</div>
								<br />
								About Salon
							</a>
						</Col>
						<Col>
							<a
								className="text-white"
								onClick={() => {
									setOpenAuthenticate(true);
								}}
							>
								<div className="menu-home">
									<img
										alt="..."
										src={require('assets/img/home/gift_card.svg')}
									/>
								</div>
								<br />
								Gift Card
							</a>
						</Col>
						<Col>
							<a
								className="text-white"
								onClick={() => {
									setOpenAuthenticate(true);
								}}
							>
								<div className="menu-home">
									<img
										alt="..."
										src={require('assets/img/home/top_up.svg')}
									/>
								</div>
								<br />
								Top up
							</a>
						</Col>
					</Row>
					<div
						className="mt-3"
						style={{
							borderRadius: '10px',
							border: '2px solid #fff',
						}}
					>
						<a
							className="text-white"
							onClick={() => {
								setOpenAuthenticate(true);
							}}
						>
							<Card
								style={{
									background: `url(${require('assets/img/card/card.png')}) no-repeat center center`,
									backgroundSize: 'cover',
									borderRadius: '10px',
								}}
							>
								<Row className="m-0">
									<div className="col-6 text-left">
										<h5
											style={{ color: '#FFF' }}
											className="mb-0 mt-1"
										>
											No.0
										</h5>
										<h3
											style={{
												color: '#dcd03f',
												fontWeight: '600',
												fontSize: '18px',
											}}
											className="text-uppercase"
										>
											New Member
										</h3>
									</div>
									<div className="col-6 text-right">
										<h3
											style={{
												color: '#FFF',
												fontWeight: '700',
											}}
											className="text-uppercase"
										>
											Guest
										</h3>
									</div>
								</Row>
							</Card>
						</a>
					</div>
				</Container>
			</div>
			<Container className="box-radius mb-2" fluid>
				<div className="mt-3">
					<span className="title-silder">
						Announcment from the salon
					</span>
				</div>
				<div className="mt-2 text-center">
					<Slider {...settings_slide_announcement}>{banner}</Slider>
				</div>
				<div
					className="text-center fixed-bottom px-3 pb-2"
					style={{ backgroundColor: '#fff' }}
				>
					<a
						className="btn btn-block btn-primary"
						rel="noopener noreferrer"
						href="#"
						onClick={() => {
							setOpenAuthenticate(true);
						}}
					>
						Book Appointment
					</a>
				</div>
			</Container>
			<AuthenticateGuest
				openAuthenticate={openAuthenticate}
				setOpenAuthenticate={setOpenAuthenticate}
			/>
		</>
	);
}
