import React from 'react';
import { Route, Switch } from 'react-router-dom';
// core components
import { Helmet } from 'react-helmet';

import routes from 'routes.js';
import withHeader from 'components/HOC/withHeader';
import ErrorPage from 'views/404';
import { Container } from 'reactstrap';

export default class Admin extends React.Component {
	componentDidUpdate() {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}
	getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout !== 'admin') return null;
			return this.renderComponent(
				prop.path,
				withHeader(prop.component, {
					title: prop.name,
					stats: prop.stats || false,
					isDisplayHeader: prop.hasOwnProperty('isDisplayHeader')
						? prop.isDisplayHeader
						: true,
					isDisplayNav: prop.hasOwnProperty('isDisplayNav')
						? prop.isDisplayNav
						: true,
				}),
				key
			);
		});
	};

	renderComponent = (path, component, key) => (
		<Route exact key={key} path={path} component={component} />
	);
	render() {
		return (
			<Container className="px-0 mx-0" fluid>
				<Helmet titleTemplate={process.env.REACT_APP_NAME + ' | %s'} />
				<Switch>
					{this.getRoutes(routes)}
					<Route component={ErrorPage} />
				</Switch>
			</Container>
		);
	}
}
