import React, { useState, useEffect } from 'react';

// reactstrap components
import { Container, Card, CardImg } from 'reactstrap';
import { Helmet } from 'react-helmet';
import CouponDetail from 'components/Modal/CouponDetail';
import { getDataCookie, API } from 'common';

export default function MyCoupon() {
	const [openCoupon, setOpenCoupon] = useState(false);
	const [couponId, setCouponId] = useState(null);
	const [listCoupon, setListCoupon] = useState([]);

	const fecthCoupon = async () => {
		try {
			let response = await API.post(
				`customer/getAllPromotions/${getDataCookie(
					process.env.REACT_APP_COOKIE_KEY
				)}`
			);
			let { payload } = response.data;
			setListCoupon(payload);
		} catch (error) {
			throw new Error('An error has occured')
		}
	};
	useEffect(() => {
		fecthCoupon();
	}, []);
	return (
		<Container className="mt-5">
			<Helmet title="My Coupon" />
			{listCoupon.length !== 0 &&
				listCoupon.map((coupon) => (
					<Card
						inverse
						className="mb-3"
						onClick={() => {
							setCouponId(coupon.id);
							setOpenCoupon(!openCoupon);
						}}
						key={coupon.id}
					>
						<CardImg
							width="100%"
							src={coupon.imageCoupon}
							alt="Coupon Image"
						/>
					</Card>
				))}
			{listCoupon.length === 0 && (
				<p className="text-center">There are no coupons available.</p>
			)}
			<CouponDetail
				couponId={couponId}
				openCoupon={openCoupon}
				setOpenCoupon={setOpenCoupon}
			/>
		</Container>
	);
}
