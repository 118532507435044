import React from 'react';

// reactstrap components
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import {Link} from 'react-router-dom'

export default function AddCreditSuccess() {
	return (
		<Container className="pt-8">
			<Helmet title="Add Credit Success" />
			<div className="text-center">
				<img src={require('assets/svg/creditsuccess.svg')} alt="Add Credit Success" />
				<p className="text-center mt-2 font-weight-bold"> Your credit has been successfully added.</p>
				<Link to="/" className="btn btn-block btn-primary">Return to Home</Link>
			</div>
		</Container>
	);
}
