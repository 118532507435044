import React, { useState, useEffect } from 'react';

// reactstrap components
import {
	Container,
	Card,
	CardImg,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import { API } from 'common';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

export default withRouter(function GiftCard({ history }) {
	const [giftCards, setGiftCards] = useState([]);
	const [giftCardsHoliday, setGiftCardsHoliday] = useState([]);
	const fecthGiftCard = async () => {
		let response_trending = await API.get('giftCard/getAllGiftCardTemplate?type=1');
		let response_holiday = await API.get('giftCard/getAllGiftCardTemplate?type=2');

		setGiftCards(response_trending.data.payload);
		setGiftCardsHoliday(response_holiday.data.payload);
	};
	
	useEffect(() => {
		fecthGiftCard();
	}, []);

	const [activeTab, setActiveTab] = useState('1');
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	return (
		<Container className="mt-2">
			<Helmet title="Gift Card" />
			<Nav tabs>
				<NavItem className="tab-gifd-card">
					<NavLink
						className={classnames({ active: activeTab === '1' })}
						onClick={() => {
							toggle('1');
						}}
					>
						Trending Gift Card
					</NavLink>
				</NavItem>
				<NavItem className="tab-gifd-card">
					<NavLink
						className={classnames({ active: activeTab === '2' })}
						onClick={() => {
							toggle('2');
						}}
					>
						Holiday Gift Card
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={activeTab} className="mt-4">
				<TabPane tabId="1">
					{giftCards &&
						giftCards.map((giftcard) => (
							<Card
								inverse
								className="mb-3"
								key={giftcard.id}
								onClick={() =>
									history.push(`/giftcarddetail`, giftcard)
								}
							>
								<CardImg
									width="100%"
									src={giftcard.urlImage}
									alt="Gift card Image"
								/>
							</Card>
						))}
					{giftCards.length === 0 && (
						<p className="text-center">
							There are no Trending Gift Card available.
						</p>
					)}
				</TabPane>
				<TabPane tabId="2">
					{giftCardsHoliday &&
						giftCardsHoliday.map((item) => (
							<Card
								inverse
								className="mb-3"
								key={item.id}
								onClick={() =>
									history.push(`/giftcarddetail`, item)
								}
							>
								<CardImg
									width="100%"
									src={item.urlImage}
									alt="Gift card Image"
								/>
							</Card>
						))}
					{giftCardsHoliday.length === 0 && (
						<p className="text-center">
							There are no Holiday Gift Card available.
						</p>
					)}
				</TabPane>
			</TabContent>
		</Container>
	);
});
