import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import constant from 'constant';
import { API, getAllSalons, setHeader } from 'common';
import {useLocation} from 'react-router-dom'

// reactstrap components
import {
	Button,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	FormFeedback,
	Modal,
	ModalBody
} from 'reactstrap';
import PhoneVerify from 'components/Modal/PhoneVerify';
import Select, { components } from 'react-select';

export default function Register({ registerState, setRegister, setLogin }) {
	let {search} = useLocation();
	const { register, handleSubmit, control, errors, setError } = useForm();
	const [phoneVerify, setPhoneVerify] = useState(false);
	const [customerId, setCustomerId] = useState(null);
	const [dataOTP, setDataOTP] = useState(null);
	const [dataSalons, setDataSalons] = useState([]);
	const [phone, setPhone] = useState(null);

	const onRegister = async (data) => {
		try {
			setHeader(data.salon.value);
			let dataBody = {
				phone: data.phone.replace(/[^\d]/g, ''),
				name: data.name,
			};
			setPhone(dataBody.phone);
			let searchParams = new URLSearchParams(search);
			searchParams.has("refld") && (dataBody.customerInviteId = searchParams.get("refld"))
			setDataOTP(dataBody);
			let { payload } = await sendRegister(dataBody);
			payload.customerId && setCustomerId(payload.customerId);
			setRegister(!registerState);
			setPhoneVerify(!phoneVerify);
		} catch (error) {
			const { data } = error.response;
			if (data.error)
				setError('account', data.error.name, data.error.message);
		}
	};

	const sendRegister = async (data) => {
		let response = await API.post(`customer/${data ? 'register' : 'resendOTP'}`, {
			payload: data || dataOTP,
		});
		return response.data;
	};

	useEffect(() => {
		getAllSalons((data) => {
			setDataSalons(
				data.payload
					.filter((item) => item.companyName)
					.map((salon) => ({
						value: salon.apiKey,
						label: salon.companyName,
					}))
			);
		});
	}, []);
	const signIn = () => {
		setRegister(!registerState);
		setLogin(true);
	};
	const ValueContainer = ({ children, ...props }) => {
		return (
			components.ValueContainer && (
				<components.ValueContainer {...props}>
					{!!children && (
						<img
							src={require('assets/svg/search.svg')}
							alt="Salon"
							style={{ position: 'absolute', left: 13 }}
						/>
					)}
					{children}
				</components.ValueContainer>
			)
		);
	};
	return (
		<>
			<Modal
				className="fixed-bottom"
				size="lg"
				isOpen={registerState}
				toggle={() => setRegister(!registerState)}
			>
				<ModalBody>
					<h2>Register</h2>
					<p className="text-muted">
						Please fill your information to register
					</p>
					<Form role="form" onSubmit={handleSubmit(onRegister)}>
						<FormGroup>
							<Controller
								as={Select}
								defaultValue=""
								name="salon"
								isSearchable={true}
								components={{
									IndicatorSeparator: () => null,
									ValueContainer,
								}}
								rules={{ required: true }}
								options={dataSalons}
								control={control}
								className="mb-2 input-group-alternative"
								placeholder="Salon"
								styles={{
									control: (base) => ({
										...base,
										height: 46,
										minHeight: 46,
										border: '1px solid #adb5bd',
										color: '#adb5bd',
									}),
									valueContainer: (base) => ({
										...base,
										paddingLeft: 37,
										color: '#adb5bd',
									}),
									placeholder: (defaultStyles) => {
										return {
											...defaultStyles,
											color: '#adb5bd',
											marginLeft: '10px',
										};
									},
								}}
							/>
							{errors.salon?.type === 'required' && (
								<FormFeedback>
									Your salon is required
								</FormFeedback>
							)}
						</FormGroup>
						<FormGroup>
							<InputGroup className="input-group-alternative mb-2 border border-light">
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										<img
											src={require('assets/svg/ic_name.svg')}
											alt="Your name"
										/>
									</InputGroupText>
								</InputGroupAddon>
								<Input
									placeholder="Your Name"
									name="name"
									type="text"
									innerRef={register({
										required: true,
									})}
								/>
							</InputGroup>
							{errors.name?.type === 'required' && (
								<FormFeedback>
									Your input is required
								</FormFeedback>
							)}
						</FormGroup>
						<FormGroup>
							<InputGroup className="input-group-alternative border mb-2 border-light">
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										<img
											src={require('assets/svg/ic_phone.svg')}
											alt="Phone Number"
										/>
									</InputGroupText>
								</InputGroupAddon>
								<Controller
									as={InputMask}
									rules={{
										required: true,
									}}
									control={control}
									mask={constant.MASK_PHONE_INPUT}
									className="form-control"
									placeholder="Phone Number"
									type="tel"
									name="phone"
									defaultValue={''}
								/>
							</InputGroup>
							{errors.phone?.type === 'required' && (
								<FormFeedback>
									Your phone is required
								</FormFeedback>
							)}
						</FormGroup>
						<div className="text-center mb-3">
							<Button
								className="btn btn-block"
								color="primary"
								type="submit"
							>
								Register
							</Button>
						</div>
						<p className="text-center" onClick={signIn}>
							Already an account? Sign in now
						</p>
					</Form>
				</ModalBody>
			</Modal>
			<PhoneVerify
				phoneVerify={phoneVerify}
				setPhoneVerify={setPhoneVerify}
				customerId={customerId}
				type="register"
				setLogin={setLogin}
				setRegister={setRegister}
				resend={sendRegister}
				phone={phone}
			/>
		</>
	);
}
