const SLIDE_DURATION = 4;
const MASK_PHONE_INPUT = "(999) 999-9999"
const CARD_VALUE = [25, 50, 75]
const SETTING_TIME_EXPIRE_CODE = 180
const SEX_VALUE = ['Male', 'Female', 'Other']
const TYPE_PAYMENT = [{
	type: 'Member Card',
	value: '2'
}, {
	type: 'Credit Card',
	value: '1'
}]
const MIN_AMOUNT = 1
export default {
	SLIDE_DURATION,
	MASK_PHONE_INPUT,
	CARD_VALUE,
	SETTING_TIME_EXPIRE_CODE,
	TYPE_PAYMENT,
	SEX_VALUE,
	MIN_AMOUNT
};