import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Card, CardImg, CardBody } from 'reactstrap';
import { API } from 'common';
export default function DetailGiftCard({
	openDetail,
	setShowDetail,
	cardId,
}) {
	const [cardDetail, setCardDetail] = useState(null);
	const [shopDetail, setShopDetail] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				let response = await API.get(
					`giftCard/getDetailGiftCard/${cardId}`
				);
				let { payload } = response.data;
				setCardDetail(payload);
				let result = await API.get(`shop/detail`);
				let { payload: shopdata } = result.data;
				setShopDetail(shopdata);
			} catch (error) {}
		};
		if (cardId && openDetail) {
			fetchData();
		}
	}, [cardId, openDetail]);
	return (
		<Modal
			isOpen={openDetail}
			toggle={() => setShowDetail(!openDetail)}
			centered={true}
		>
			
			{cardDetail && (
				<>
					<Card inverse>
						<CardImg
							width="100%"
							height="200"
							src={cardDetail.imageBackgroundGift}
							alt="Gift Card Image"
						/>
					</Card>
					<ModalBody>
						<p className="mb-2">
							<small className="text-muted">Amount:</small>
							<span className="float-right font-weight-bold">
								{cardDetail.amount &&
									`$${cardDetail.amount}`}
							</span>
						</p>
						<p className="mb-2">
							<small className="text-muted">Full Name:</small>
							<span className="float-right font-weight-bold">
								{cardDetail?.customerNameTo}
							</span>
						</p>
						<p className="mb-2">
							<small className="text-muted">Phone:</small>
							<span className="float-right font-weight-bold">
								{cardDetail?.phoneTo}
							</span>
						</p>
						<p className="mb-2">
							<small className="text-muted">Email:</small>
							<span className="float-right font-weight-bold">
								{cardDetail?.emailTo}
							</span>
						</p>

						<hr
							style={{ borderTop: '1px dashed #333' }}
							className="m-0"
						/>
						<div className="text-center">
							{cardDetail.imageGiftCode && (
								<img
									width="200"
									alt="QR Code"
									src={cardDetail.imageGiftCode}
								/>
							)}
						</div>
						{shopDetail && (
							<>
								<p className="text-uppercase h3 text-center font-weight-max text-escape">
									{shopDetail && shopDetail?.companyName}
								</p>
								<p className="text-center text-escape mb-2">
									{`${shopDetail && shopDetail?.address}, ${shopDetail && shopDetail?.city}, ${shopDetail && shopDetail?.state} ${shopDetail && shopDetail?.zip}`}
								</p>
								<p className="text-center text-escape mb-0">
									{(shopDetail && shopDetail.phone) ? `(${shopDetail.phone.slice(2).slice(0, 3)}) ${shopDetail.phone.slice(2).slice(3, 6)} - ${shopDetail.phone.slice(2).slice(6)}` : '' }

								</p>
							</>
						)}
					</ModalBody>
				</>
			)}
		</Modal>
	);
}
