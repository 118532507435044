import React, { useState, useEffect } from 'react';

// reactstrap components
import { Helmet } from 'react-helmet';
import { Container, Card, CardBody, CardImg } from 'reactstrap';
import { API } from 'common';
import { useLocation } from 'react-router-dom';

export default function TransactionDetail() {
	let data = useLocation();
	var { cardId } = data.state;
	var { timePayment } = data.state;

	const [cardDetail, setCardDetail] = useState(null);
	const [shopDetail, setShopDetail] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				let response = await API.get(
					`giftCard/getDetailGiftCard/${cardId}`
				);
				let { payload } = response.data;
				setCardDetail(payload);
				let result = await API.get(`shop/detail`);
				let { payload: shopdata } = result.data;
				setShopDetail(shopdata);
			} catch (error) {}
		};
		if (cardId) {
			fetchData();
		}
	}, [cardId]);
	return (
		<>
			<Helmet title="Hunt Spins" />
			<Container style={{ backgroundColor: 'rgb(233 236 239 / 27%)'}}>
				<Card
					style={{
						marginBottom: '20px',
						border: 'none',
						borderBottom: '1px solid #ccc',
						borderRadius: '0px',
						backgroundColor: 'rgb(233 236 239 / 27%)'
					}}
				>
					<CardBody className="h-100 p-0 mt-4 mb-4">
						<div className="float-left">
							<h3
								style={{
									fontWeight: '700',
									fontSize: '18px',
								}}
							>
								Buy Gift Card
							</h3>
							{timePayment}
						</div>
						<div className="float-right">
							<h3
								style={{
									color: 'green',
									fontWeight: '700',
									marginTop: '20px',
								}}
								className="badge badge-success"
							>
								Success
							</h3>
						</div>
					</CardBody>
				</Card>
				{cardDetail && (
					<div
						style={{
							marginBottom: '20px',
							border: 'none',
							borderBottom: '1px solid #ccc',
							borderRadius: '0px',
						}}
					>
						<p className="mb-2">
							<small className="text-muted">Amount:</small>
							<span className="float-right font-weight-bold">
								{cardDetail &&
									cardDetail.amount &&
									`$${cardDetail.amount}`}
							</span>
						</p>
						<p className="mb-2">
							<small className="text-muted">
								Gift Card Code:
							</small>
							<span className="float-right font-weight-bold">
								{cardDetail &&
									cardDetail.giftCardCode &&
									`#${cardDetail.giftCardCode}`}
							</span>
						</p>
						<p className="mb-2">
							<small className="text-muted">Full Name:</small>
							<span className="float-right font-weight-bold">
								{cardDetail && cardDetail?.customerNameTo}
							</span>
						</p>
						<p className="mb-2">
							<small className="text-muted">Phone:</small>
							<span className="float-right font-weight-bold">
								{cardDetail && cardDetail?.phoneTo}
							</span>
						</p>
						<p className="mb-2">
							<small className="text-muted">Email:</small>
							<span className="float-right font-weight-bold">
								{cardDetail && cardDetail?.emailTo}
							</span>
						</p>
						<Card inverse>
							<CardImg
								width="100%"
								height="200"
								src={cardDetail.imageBackgroundGift}
								alt="Gift Card Image"
							/>
						</Card>
					</div>
				)}
				{cardDetail && (
					<>
						<p className="mb-2">
							<small className="text-muted">Amount:</small>
							<span className="float-right font-weight-bold">
								{cardDetail &&
									cardDetail.amount &&
									`$${cardDetail.amount}`}
							</span>
						</p>
						<p className="mb-2">
							<small className="text-muted">Full Name:</small>
							<span className="float-right font-weight-bold">
								{cardDetail && cardDetail?.customerNameTo}
							</span>
						</p>
						<p className="mb-2">
							<small className="text-muted">Phone:</small>
							<span className="float-right font-weight-bold">
								{cardDetail && cardDetail?.phoneTo}
							</span>
						</p>
						<p className="mb-2">
							<small className="text-muted">Email:</small>
							<span className="float-right font-weight-bold">
								{cardDetail && cardDetail?.emailTo}
							</span>
						</p>

						<hr
							style={{ borderTop: '1px dashed #333' }}
							className="m-0"
						/>
						<div className="text-center">
							{cardDetail && cardDetail.imageGiftCode && (
								<img
									width="200"
									alt="QR Code"
									src={cardDetail.imageGiftCode}
								/>
							)}
						</div>
						{shopDetail && (
							<>
								<p className="text-uppercase h3 text-center font-weight-max text-escape">
									{shopDetail && shopDetail?.companyName}
								</p>
								<p className="text-center text-escape mb-2">
									{`${shopDetail && shopDetail?.address}, ${
										shopDetail && shopDetail?.city
									}, ${shopDetail && shopDetail?.state} ${
										shopDetail && shopDetail?.zip
									}`}
								</p>
								<p className="text-center text-escape mb-0">
									{shopDetail && shopDetail.phone
										? `(${shopDetail.phone
												.slice(2)
												.slice(
													0,
													3
												)}) ${shopDetail.phone
												.slice(2)
												.slice(
													3,
													6
												)} - ${shopDetail.phone
												.slice(2)
												.slice(6)}`
										: ''}
								</p>
							</>
						)}
					</>
				)}
			</Container>
		</>
	);
}
