import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Card, CardImg, CardBody, Container } from 'reactstrap';
import { API, setHeader } from 'common';
import { useParams } from "react-router-dom";
export default function ShowGiftCardDetail() {
	const [cardDetail, setCardDetail] = useState(null);
	const [shopDetail, setShopDetail] = useState(null);
	const [dataImage, setDataImage] = useState('');
	const { api_key, id } = useParams();
	useEffect(() => {
		const fetchData = async () => {
			try {
				setHeader(api_key);
				let response = await API.get(
					`giftCard/getDetailGiftCard/${id}`
				);
				let { payload } = response.data;
				
				setCardDetail(payload);

				let result = await API.get(`shop/detail`);
				if (result.data.code == 200) {
					setShopDetail(result.data.payload);
				}
			} catch (error) {}
		};
		fetchData();
	}, []);

	return (
		<Container>
			<h2 className="text-center">Gift Card Detail</h2>
			{cardDetail && (
				<div>
					<Card inverse>
						<CardImg
							width="100%"
							height="200"
							src={cardDetail.imageBackgroundGift}
							alt="Gift Card Image"
						/>
					</Card>
					<p className="mb-2">
						<small className="text-muted">Amount:</small>
						<span className="float-right font-weight-bold">
							{cardDetail.amount &&
								`$${cardDetail.amount}`}
						</span>
					</p>
					<p className="mb-2">
						<small className="text-muted">Full Name:</small>
						<span className="float-right font-weight-bold">
							{cardDetail?.customerNameTo}
						</span>
					</p>
					<p className="mb-2">
						<small className="text-muted">Phone:</small>
						<span className="float-right font-weight-bold">
							{cardDetail?.phoneTo}
						</span>
					</p>
					<p className="mb-2">
						<small className="text-muted">Email:</small>
						<span className="float-right font-weight-bold">
							{cardDetail?.emailTo}
						</span>
					</p>

					<hr
						style={{ borderTop: '1px dashed #333' }}
						className="m-0"
					/>
					<div className="text-center">
						{cardDetail.imageGiftCode && (
							<img
								width="200"
								alt="QR Code"
								src={cardDetail.imageGiftCode}
							/>
						)}
					</div>
					{shopDetail && (
						<>
							<p className="text-uppercase h3 text-center font-weight-max text-escape">
								{shopDetail && shopDetail?.companyName}
							</p>
							<p className="text-center text-escape mb-2">
								{`${shopDetail && shopDetail?.address}, ${shopDetail && shopDetail?.city}, ${shopDetail && shopDetail?.state} ${shopDetail && shopDetail?.zip}`}
							</p>
							<p className="text-center text-escape mb-0">
								{(shopDetail && shopDetail.phone) ? `(${shopDetail.phone.slice(2).slice(0, 3)}) ${shopDetail.phone.slice(2).slice(3, 6)} - ${shopDetail.phone.slice(2).slice(6)}` : '' }

							</p>
						</>
					)}
				</div>
			)}
		</Container>
	);
}
