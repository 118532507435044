import React, { useState, useEffect } from 'react';

// reactstrap components
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Card, CardBody, Row } from 'reactstrap';

import { API, getDataCookie } from 'common';
import MenuBottom from 'components/Common/MenuBottom';

export default function ReferralHistory() {
	const [listReferralHistory, setListReferralHistory] = useState([]);
	const [giftPurchase, setGiftPurchase] = useState('');
	const [totalAddCredit, setTotalAddCredit] = useState('');
	const [totalSignUp, setTotalSignUp] = useState('');

	const fecthListReferralHistory = async () => {
		const response = await API.get(
			`customer/getReferralHistory/${getDataCookie(
				process.env.REACT_APP_COOKIE_KEY
			)}`
		);
		let { payload } = response.data;
		if (payload.length !== 0) {
			if (payload.details) {
				setListReferralHistory(payload.details);
			}
			setGiftPurchase(payload.giftPurchase);
			setTotalAddCredit(payload.totalAddCredit);
			setTotalSignUp(payload.totalSignUp);
		}
	};

	useEffect(() => {
		fecthListReferralHistory();
	}, []);

	const html_list= listReferralHistory.map((item, index) => {
		var icon = '';
		if (item.type == "GIFT_PURCHASE") {
			icon = 'Icon3';
		}
		else if (item.type == "ADD_CREDIT") {
			icon = 'Icon1';
		}else if (item.type == "SIGN_UP") {
			icon = 'Icon2';
		}
		return (
			<Card key={index}>
				<CardBody className="h-100" style={{padding:'5px!important'}}>
					<div className="float-left">
						<img style={{float:'left'}} width="50px" src={require(`assets/img/referral_history/${icon}.png`)} alt="img" />
						<div style={{float:'left', marginLeft: '5px'}}>
							<h3
								style={{
									fontWeight: '600',
									fontSize: '18px',
								}}
							>
								{item.title}
							</h3>
							{item.createdDate}
						</div>
						
					</div>
					<div className="float-right">
						<h3
							style={{
								color: '#1175bf',
								fontWeight: '700',
							}}
						>
							+{item.totalSpin} Spin
						</h3>
					</div>
				</CardBody>
			</Card>
		);
	});

	return (
		<>
			<Helmet title="Referral history" />
			<div className="header bg-white pb-3 pt-3 ">
				<Container fluid>
					<div className="header-body text-center">
						<div className="clearfix">
							<Link to="/">
								<img
									className="float-left"
									src={require('assets/img/icons/IC_Back.png')}
									width="30"
									alt="Go Back"
								/>
							</Link>
							<h6 className="h2 text-dart d-inline-block mb-0">
							Referral History
							</h6>
						</div>
					</div>
				</Container>
			</div>
			<Container>
				<div id="count-result">
					<div className="item-count-result">
						<h2>{(giftPurchase != '' && giftPurchase > 0) ? giftPurchase : 0}</h2>
						<p>New Sign Up</p>
					</div>
					<div className="item-count-result" style={{borderLeft: '1px solid #fff', borderRight: '1px solid #fff'}}>
						<h2>${(totalAddCredit != '' && totalAddCredit > 0) ? totalAddCredit : 0}</h2>
						<p>Add credit</p>
					</div>
					<div className="item-count-result">
						<h2>{(totalSignUp != '' && totalAddCredit > 0) ? totalSignUp : 0}</h2>
						<p>Gift Purchase</p>
					</div>
				</div>
				<h2 className="mt-4">Referral history</h2>
				<div className="pb-5">
					{listReferralHistory.length > 0
						? html_list
						: 'Not available list referral history!'}
					{listReferralHistory.length > 0
					? html_list
					: 'Not available list referral history!'}
				</div>
				<div className="fixed-bottom">
					<MenuBottom typeTab="referralhistory"/>
				</div>
			</Container>
		</>
	);
}
