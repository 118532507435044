import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
	Container,
	Card,
	CardImg,
	Form,
	Label,
	Input,
	ButtonGroup,
	Button,
	FormGroup,
	FormFeedback,
	Row,
	Col,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import NotifyPay from 'components/Modal/NotifycationGiftCard';
import { useForm, Controller } from 'react-hook-form';
import { API, getDataCookie } from 'common';
import constant from 'constant';
import InputMask from 'react-input-mask';

var count = 0;
export default function GiftCardDetail() {
	let { state: dataDetail } = useLocation();
	let { balance } = JSON.parse(localStorage.getItem('infor_customer'));
	const [statusPay, setStatusPay] = useState(false);
	const [typePayment, setTypePayment] = useState(
		constant.TYPE_PAYMENT[0].type + ` ($${balance})`
	);
	const [openFormCreditCard, setOpenFormCreditCard] = useState(false);
	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		control,
		errors,
	} = useForm();
	const [openNotyfiGift, setOpenNotyfiGift] = useState(false);
	const [giftcardid, setgiftcardid] = useState(null);
	const onSubmit = (data) => {
		if (data.paymentType == 1) {
			var flag_isset_error = false;

			var card_number_format = '';
			if (!data.cardNumber.match(/^\d{16}$/)) {
				card_number_format =
					'Please enter Card Number the correct format';
				flag_isset_error = true;
			}
			document.getElementById(
				'card_number_format'
			).textContent = card_number_format;

			var expires_date_format = '';
			if (!data.expireDate.match(/^(0[1-9]|1[1-2])\/\d{2}$/)) {
				expires_date_format =
					'Please enter Expired Date the correct format';
				flag_isset_error = true;
			}
			document.getElementById(
				'expires_date_format'
			).textContent = expires_date_format;

			var cvv_format = '';
			if (!data.cvv.match(/^\d{3}$/)) {
				cvv_format = 'Please enter CVV the correct format';
				flag_isset_error = true;
			}
			document.getElementById('cvv_format').textContent = cvv_format;

			if (flag_isset_error) return;

			// conver date
			data.expireDate =
				data.expireDate.substr(0, 2) + data.expireDate.substr(-2);
		}
		data.amount = parseInt(data.amount.substr(1));
		data.customerId = getDataCookie(process.env.REACT_APP_COOKIE_KEY);
		data.urlImageGift = dataDetail.urlImage;
		var paymentType = data.paymentType;
		// data.paymentType = 2;
		API.post('giftCard/buyGiftCard', {
			payload: data,
		})
			.then((response) => {
				let { payload } = response.data;
				let { code } = response.data;
				if (code == 200) {
					setgiftcardid(payload.giftCardId);
					setStatusPay(true);
					setOpenNotyfiGift(true);
				}
				else {
					setStatusPay(false);
					setOpenNotyfiGift(false);
				}
			})
			.then(async () => {
				if (paymentType == constant.TYPE_PAYMENT[0].value) {
					let response_customer = await API.get(
						`customer/getDetailCustomer/${getDataCookie(
							process.env.REACT_APP_COOKIE_KEY
						)}`
					);
					let { payload } = response_customer.data;
					let infor_customer = JSON.parse(
						localStorage.getItem('infor_customer')
					);
					infor_customer.balance = payload.balance;
					localStorage.setItem(
						'infor_customer',
						JSON.stringify(infor_customer)
					);
					setTypePayment(
						constant.TYPE_PAYMENT[0].type + ` ($${payload.balance})`
					);
				}
			})
			.catch((error) => {
				const { data } = error.response;
				document.getElementById('buy_gift_card').innerHTML =
					data.message;
				// let {data} = error.response;
				// setStatusPay(!statusPay);
			})
	};

	const changeTypePayment = (e) => {
		constant.TYPE_PAYMENT.map((item) => {
			if (item.value == e.target.value) {
				if (constant.TYPE_PAYMENT[0].value == item.value) {
					setTypePayment(item.type + ` ($${balance})`);
				} else {
					setTypePayment(item.type);
				}
				setOpenFormCreditCard(!openFormCreditCard);
			}
		});
	};

	const reset_list_balance = () => {
		var radioButtons = document.getElementsByName('radio_card');
		for (var i = 0; i < radioButtons.length; i++) {
			if (radioButtons[i].checked) radioButtons[i].checked = false;
		}
	};
	const reload_list_card_value = (e) => {
		reset_list_balance();
		if (e.target.value.indexOf('$') == -1) {
			e.target.value = '$' + e.target.value;
		}
	};

	const double_click_balance = () => {
		// increment count
		count++;
		setTimeout(() => {
			// listen for double clicks
			if (count === 2) {
				reset_list_balance();
				document.getElementById('amount').value = '';
			}
			// reset count
			count = 0;
		}, 250); // 250 ms
	};

	return (
		<Container className="pt-2">
			<Helmet title="Gift Card Detail" />
			<Card inverse>
				<CardImg
					width="100%"
					style={{ maxHeight: '400px' }}
					src={dataDetail.urlImage}
					alt="Card"
				/>
			</Card>
			<Form
				className="form mt-2 mb-2"
				onSubmit={handleSubmit(onSubmit)}
				id="form-gift-card"
				autoComplete="off"
			>
				<FormFeedback>
					<h2
						className="text-center"
						style={{ color: 'red' }}
						id="buy_gift_card"
					></h2>
				</FormFeedback>
				<FormGroup className="mb-3">
					<Label for="amount" style={{ fontWeight: '600' }}>
						Gift Card Value
					</Label>
					<Input
						type="text"
						name="amount"
						id="amount"
						min={constant.MIN_AMOUNT}
						placeholder="Different Amount"
						defaultValue={getValues('amount')}
						innerRef={register({
							required: true,
						})}
						placeholder="$0"
						onClick={(event) => {
							event.target.value = '';
						}}
						onChange={(event) => reload_list_card_value(event)}
						pattern="[$][1-9]+[0]*"
					/>
					{errors.amount?.type === 'required' && (
						<FormFeedback className="mt-0">
							Gift Card Value is required
						</FormFeedback>
					)}
				</FormGroup>
				<div className="text-center">
					<FormGroup tag="fieldset" className="mb-1">
						<ButtonGroup
							className="d-flex justify-content-between"
							size="lg"
						>
							{constant.CARD_VALUE.map((i, k) => (
								<FormGroup
									check
									style={{ paddingLeft: '0' }}
									key={k}
								>
									<Input
										type="radio"
										name="radio_card"
										id={`radio_card_${i}`}
										onClick={() => {
											setValue('amount', `$${i}`);
											double_click_balance();
										}}
										style={{ visibility: 'hidden' }}
									/>
									<Label
										for={`radio_card_${i}`}
										className="radio_card_label btn-effect-click"
									>
										{`$${i}`}
									</Label>
								</FormGroup>
							))}
						</ButtonGroup>
					</FormGroup>
				</div>
				<h4>Send to:</h4>
				<FormGroup className="form-customize mb-0">
					<Input
						type="text"
						name="customerName"
						id="customerName"
						placeholder="Full Name"
						className="form-customize-input"
						innerRef={register({
							required: true,
						})}
					/>
					<Label
						for="customerName"
						className="form-customize-label text-uppercase"
					>
						Full Name <span className="text-red">*</span>
					</Label>
				</FormGroup>
				{errors.customerName?.type === 'required' && (
					<FormFeedback className="mt-0">
						Your name is required
					</FormFeedback>
				)}
				<FormGroup className="form-customize mt-2 mb-0">
					<Controller
						as={InputMask}
						control={control}
						mask={constant.MASK_PHONE_INPUT}
						className="form-control form-customize-input"
						defaultValue=""
						type="tel"
						id="phoneNumber"
						placeholder="Phone Number"
						name="phoneNumber"
						rules={{
							required: true,
							pattern: /\([0-9]{3}\) [0-9]{3}-[0-9]{4}/,
						}}
					/>
					<Label
						for="phoneNumber"
						className="form-customize-label text-uppercase"
					>
						Phone Number <span className="text-red">*</span>
					</Label>
				</FormGroup>
				{errors.phoneNumber?.type === 'required' && (
					<FormFeedback className="mt-0">
						Your phone is required
					</FormFeedback>
				)}
				{errors.phoneNumber?.type === 'pattern' && (
					<FormFeedback className="mt-0">
						Please enter the correct format
					</FormFeedback>
				)}
				<FormGroup className="form-customize mt-2 mb-0">
					<Input
						type="email"
						name="email"
						id="email"
						placeholder="Email"
						className="form-customize-input"
						innerRef={register({
							required: true,
						})}
					/>
					<Label
						for="email"
						className="form-customize-label text-uppercase"
					>
						Email <span className="text-red">*</span>
					</Label>
				</FormGroup>
				{errors.email?.type === 'required' && (
					<FormFeedback className="mt-0 mb-3">
						Your email is required
					</FormFeedback>
				)}
				<h4 style={{ fontWeight: '600', color: '#075690' }}>
					Payment Method
				</h4>
				<FormGroup className="form-customize mb-0">
					<Input
						type="select"
						name="paymentType"
						id="paymentType"
						innerRef={register({
							required: true,
						})}
						defaultValue=""
						className="form-customize-input"
						onChange={changeTypePayment}
						style={{ paddingLeft: '0.5rem' }}
					>
						{constant.TYPE_PAYMENT.map((item, index) => (
							<option value={item.value} key={index}>
								{item.type}
							</option>
						))}
					</Input>
					<Label
						for="paymentType"
						className="form-customize-label text-uppercase"
					>
						Select Payment Method{' '}
						<span className="text-red">*</span>
					</Label>
				</FormGroup>
				{openFormCreditCard && (
					<>
						<p className="mb-1 mt-1">Your Card Details</p>
						<FormGroup className="form-customize mt-2 mb-0">
							<Input
								type="input"
								name="cardName"
								id="cardName"
								required="required"
								placeholder="Cardholder Name"
								innerRef={register({
									required: true,
								})}
								required="required"
								className="form-customize-input form-control"
							/>
							<Label
								for="cardName"
								className="form-customize-label text-uppercase"
							>
								Cardholder Name
								<span className="text-red">*</span>
							</Label>
						</FormGroup>
						<FormGroup className="form-customize mt-2 mb-0">
							<Controller
								type="tel"
								as={InputMask}
								control={control}
								mask="9999999999999999"
								className="form-customize-input form-control"
								placeholder="Card Number"
								name="cardNumber"
								defaultValue=""
								required="required"
								rules={{
									required: true,
								}}
							/>
							<Label
								for="cardNumber"
								className="form-customize-label text-uppercase"
							>
								Card Number
								<span className="text-red">*</span>
							</Label>
						</FormGroup>
						<FormFeedback
							className="mt-0"
							id="card_number_format"
						></FormFeedback>
						<Row form>
							<Col xs={6}>
								<FormGroup className="form-customize mt-2 mb-0">
									<Controller
										type="tel"
										as={InputMask}
										control={control}
										mask="99/99"
										className="form-customize-input form-control"
										placeholder="MM/YY"
										name="expireDate"
										defaultValue=""
										required="required"
										rules={{
											required: true,
										}}
									/>
									<Label
										for="expireDate"
										className="form-customize-label text-uppercase"
									>
										Expired Date
										<span className="text-red">*</span>
									</Label>
								</FormGroup>
								<FormFeedback
									className="mt-0"
									id="expires_date_format"
								></FormFeedback>
							</Col>
							<Col xs={6}>
								<FormGroup className="form-customize mt-2 mb-0">
									<Controller
										type="tel"
										as={InputMask}
										control={control}
										mask="999"
										className="form-customize-input form-control"
										placeholder="CVV"
										name="cvv"
										defaultValue=""
										rules={{
											required: true,
										}}
										required="required"
									/>
									<Label
										for="cvv"
										className="form-customize-label text-uppercase"
									>
										CVV
										<span className="text-red">*</span>
									</Label>
								</FormGroup>
								<FormFeedback
									className="mt-0"
									id="cvv_format"
								></FormFeedback>
							</Col>
						</Row>
					</>
				)}
				<Button
					color="primary"
					type="submit"
					className="btn btn-block btn-primary mt-2"
					key={new Date()}
				>
					Pay with {typePayment}
				</Button>
			</Form>
			<NotifyPay
				openNotyfiGift={openNotyfiGift}
				setOpenNotyfiGift={setOpenNotyfiGift}
				status={statusPay}
				giftcardid={giftcardid}
			/>
		</Container>
	);
}
