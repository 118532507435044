import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';

export default function AuthenticateGuest({
	openAuthenticate,
	setOpenAuthenticate,
}) {
	return (
		<Modal
			isOpen={openAuthenticate}
			toggle={() => setOpenAuthenticate(!openAuthenticate)}
			centered={true}
		>
			<ModalBody>
				<a
					className="btn btn-block btn-primary"
					size="lg"
					href="#"
					onClick={() => {
						localStorage.setItem('flag_is_register', 0);
						window.location.href = "/login" 
					}}
				>
					Sign In
				</a>
				<a
					className="btn btn-block btn-primary"
					size="lg"
					href="#"
					onClick={() => {
						localStorage.setItem('flag_is_register', 1);
						window.location.href = "/login" 
					}}
				>
					Register
				</a>
			</ModalBody>
		</Modal>
	);
}
