import React, { useState, useEffect } from 'react';

// reactstrap components
import { Helmet } from 'react-helmet';
import {
	Container,
	Card,
	CardHeader,
	CardBody,
	Row,
	Col,
	Button,
} from 'reactstrap';

import { useHistory, Link } from 'react-router-dom';
import FaceBookShareButton from 'components/Common/FacebookShareButton';
import MenuBottom from 'components/Common/MenuBottom';

export default function HuntSpin() {
	let history = useHistory();
	return (
		<>
			<Helmet title="Hunt Spins" />
			<div className="header bg-white pb-3 pt-3 ">
				<Container fluid>
					<div className="header-body text-center">
						<div className="clearfix">
							<Link to="/">
								<img
									className="float-left"
									src={require('assets/img/icons/IC_Back.png')}
									width="30"
									alt="Go Back"
								/>
							</Link>
							<h6 className="h2 text-dart d-inline-block mb-0">
							Hunt Spins
							</h6>
						</div>
					</div>
				</Container>
			</div>
			<Container>
				<p className="font-weight-bold mb-2">Hunt Spins</p>
				<Card className="mb-3">
					<CardHeader className="bg-secondary py-1">
						<b style={{ color: '#0081e0' }}>+1 Spin</b>
						<span className="float-right">
							<img
								src={require('assets/svg/facebook.svg')}
								alt="share facebook"
							/>
						</span>
					</CardHeader>
					<CardBody className="py-3">
						<p className="font-weight-bold">Share on Facebook</p>
						<p className="text-muted">Description</p>
						<FaceBookShareButton />
					</CardBody>
				</Card>
				<Card className="mb-6">
					<CardHeader className="bg-secondary py-1">
						<b style={{ color: '#0081e0' }}>+2 Spins</b>
						<span className="float-right">
							<img
								src={require('assets/svg/bitcoin.svg')}
								alt="+2 spins"
							/>
						</span>
					</CardHeader>
					<CardBody className="py-3">
						<p className="font-weight-bold">
							Add credit into Member credit
						</p>
						<p className="text-muted">Description</p>
						<Row className="mb-3">
							<Col xs="6">
								<Card>
									<CardHeader className="bg-secondary text-center py-1 font-weight-bold">
										$25
									</CardHeader>
									<CardBody className="text-center py-1">
										+1 Spin
									</CardBody>
								</Card>
							</Col>
							<Col xs="6">
								<Card>
									<CardHeader className="bg-secondary text-center py-1 font-weight-bold">
										$50
									</CardHeader>
									<CardBody className="text-center py-1">
										+2 Spins
									</CardBody>
								</Card>
							</Col>
						</Row>
						<Button
							block
							color="primary"
							onClick={() => history.push('/addcredit')}
						>
							Add Credit
						</Button>
					</CardBody>
				</Card>
				<div className="fixed-bottom">
					<MenuBottom typeTab="huntspin" />
				</div>
			</Container>
		</>
	);
}
