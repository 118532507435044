import React from 'react';
import { Card, CardTitle, CardImg, CardImgOverlay } from 'reactstrap';

export default function InforCard({ prop_class }) {
	let infor_customer = JSON.parse(localStorage.getItem('infor_customer'));
	return (
		<Card inverse className={prop_class}>
			<CardImg
				width="100%"
				style={{ maxHeight: '400px' }}
				src={require('assets/img/card/card.png')}
				alt="Add Credit Card"
			/>
			<CardImgOverlay>
				<CardTitle>
					<div className="float-left">
						No.{infor_customer?.cardNumber}
						<br />
						<h3
							style={{
								color: '#dcd03f',
								fontWeight: '600',
								fontSize: '18px',
							}}
							className="text-uppercase"
						>
							{infor_customer?.levelMembership}
						</h3>
					</div>
					<div className="float-right">
						<h3 className="text-uppercase" style={{ color: '#FFF', fontWeight: '700' }}>
							{infor_customer?.shopName}
						</h3>
					</div>
				</CardTitle>
				<CardTitle
					style={{
						bottom: '0',
						position: 'absolute',
					}}
				>
					<h2
						style={{
							color: '#fff',
							fontWeight: '700',
							fontSize: '18px',
						}}
						className="text-uppercase"
					>
						{infor_customer?.customerName}
					</h2>
					<span>Account Balance: ${infor_customer?.balance}</span>
				</CardTitle>
			</CardImgOverlay>
		</Card>
	);
}
