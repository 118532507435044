import React, { useEffect, useState } from 'react';
import { API, getDataCookie } from 'common';
import { useHistory } from 'react-router-dom';
import {
	Media,
	Container,
	Row,
	Col,
	Card,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Form,
	Input,
	Button,
	FormGroup,
	FormFeedback,
	Label,
	ButtonGroup,
} from 'reactstrap';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import Social from 'components/Modal/Social';
import { FacebookShareButton } from 'react-share';

export default function AboutSalon() {
	const [salon, setSalon] = useState(null);
	const { register, handleSubmit, errors, setError } = useForm();
	const [stars, setStars] = useState(0);
	const [listByStars, setListByStars] = useState([]);
	const [openSocial, setOpenSocial] = useState(false);
	const [social, setSocial] = useState(null);

	let history = useHistory();
	useEffect(() => {
		const fetchAPI = async () => {
			let response = await API.get('shop/detail');
			// console.log(response.data.payload);
			let { payload } = response.data;
			setSalon(payload);
			if (payload.aboutUs && payload.aboutUs != '') {
				document.getElementById('tab_about').innerHTML =
					payload.aboutUs;
			} else {
				document.getElementById('tab_about').innerHTML =
					'<p>Not available about</p>';
			}
		};
		fetchAPI();
	}, []);

	const [activeTab, setActiveTab] = useState('1');
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const onSubmit = async (data) => {
		try {
			data.star = stars
			if (data.categoryId && data.categoryId != "") {
				data.categoryId = [data.categoryId];
			}
			else {
				data.categoryId = [0];
			}
			data.customerId = getDataCookie(process.env.REACT_APP_COOKIE_KEY);
			const response = await API.post('shop/updateReviewShop', {
				payload: data,
			});
			let { code } = response.data;
			if (code === 200) {
				document.getElementById('result_review').innerHTML =
					`<h2 class="text-center" style="color:#0085e7">Review success!</h2>`;
				if (stars > 3) {
					const response = await API.get('socialChannel/list');
					let { code } = response.data;
					if (code === 200) {
						setOpenSocial(true);
						setSocial(response.data.payload)
					}
				}
			}
		} catch (error) {
			document.getElementById('result_review').innerHTML =
				`<h2 class="text-center" style="color:red">Review fail!</h2>`;
		}
		setStars(0);
		setListByStars([]);
		document.getElementById('content').value = '';
	};
	

	const select_stars = async (index) => {
		document.getElementById('result_review').innerHTML = '';
		if (index < 4) {
			const response = await API.get(`reviews/category/listByStar?star=${index}&type=2`);
			let { code } = response.data;
			if (code === 200) {
				setListByStars(response.data.payload)
			}
		}
		else {
			setListByStars([]);
		}
		setStars(index);
	}

	var list_stars = [1,2,3,4,5];
	const review_stars = list_stars.map((item, index) => {
		var color = '';
		if (item <= stars) {
			color = "#dada00";
		}
		return (<span key={item} style={{color: color}} className="mr-2" onClick={() => select_stars(item)}><i className="fa fa-star" style={{fontSize:"35px"}}></i></span>);
	});
	
	const html_list_by_stars = listByStars.map((item, index) => {
		return (
			<FormGroup
				check
				style={{ paddingLeft: '0' }}
				key={index}
			>
				<Input
					type="radio"
					name="categoryId"
					id={`category_item_${index}`}
					value={item.categoryId}
					style={{ visibility: 'hidden' }}
					innerRef={register({
						// required: true,
					})}
				/>
				<Label
					for={`category_item_${index}`}
					className="radio_category_name_label"
				>
					{item.categoryName}
				</Label>
			</FormGroup>
		)
	});

	if (salon) {
		return (
			<>
				<div className="m-3 fixed-top">
					<img
						className="float-left"
						src={require('assets/img/icons/IC_Back_white.png')}
						width="30"
						onClick={() => history.goBack()}
						alt="Go Back"
					/>
					<FacebookShareButton
						className="float-right"
						url={`${window.location.origin}/guest/${getDataCookie(process.env.REACT_APP_API_X_KEY)}`}
					>
						<img
							src={require('assets/img/icons/IC_Share_white.png')}
							width="30"
							alt="Share"
						/>
					</FacebookShareButton>
				</div>
				<Card
					className="mb-3 p-6 border-0 text-center rounded-0"
					style={{
						backgroundImage: `url(https://images.unsplash.com/photo-1516975080664-ed2fc6a32937?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80)`,
						backgroundSize: 'cover',
					}}
				>
					<blockquote className="blockquote mb-0">
						<h2
							className="text-uppercase text-white font-weight-max"
							style={{ fontSize: '1.5rem' }}
						>
							{salon?.companyName}
						</h2>
					</blockquote>
				</Card>
				<Container>
					<Media>
						<Media body>
							<Media heading tag="h3">
								{salon?.companyName}
							</Media>
							{`${salon?.address}, ${salon?.city}, ${salon?.state} ${salon?.zip}`}
							<div>
								<span
									style={{ color: '#dada00' }}
									className="mr-2"
								>
									<i className="fa fa-star"></i>
								</span>
								<span
									style={{ color: '#dada00' }}
									className="mr-2"
								>
									<i className="fa fa-star"></i>
								</span>
								<span
									style={{ color: '#dada00' }}
									className="mr-2"
								>
									<i className="fa fa-star"></i>
								</span>
								<span
									style={{ color: '#dada00' }}
									className="mr-2"
								>
									<i className="fa fa-star"></i>
								</span>
								<span style={{ color: '#dada00' }}>
									<i className="fa fa-star"></i>
								</span>
							</div>
						</Media>
						<Media right className="text-right">
							<Media
								object
								src={'data:image/jpg;base64,' + salon?.avatar}
								className="rounded-circle"
								width="50px"
								height="50px"
								alt={salon?.companyName}
							/>
						</Media>
					</Media>
				</Container>
				<Row
					className="mt-2 p-3 mx-0"
					style={{ backgroundColor: '#F8F9F9' }}
				>
					<Col className="px-1">
						<div className="text-center">
							<img
								width="30px"
								height="30px"
								alt="..."
								src={require('assets/img/icons/time.png')}
								className="mb-2"
							/>
							<br />
							<small>12/7</small>
						</div>
					</Col>
					<Col className="px-1">
						<div className="text-center">
							<img
								width="30px"
								height="30px"
								alt="..."
								src={require('assets/img/icons/salon.png')}
								className="mb-2"
							/>
							<br />
							<small>Salon</small>
						</div>
					</Col>
					<Col className="px-1">
						<div className="text-center">
							<img
								width="30px"
								height="30px"
								alt="..."
								src={require('assets/img/icons/parking.png')}
								className="mb-2"
							/>
							<br />
							<small>Parking Paid</small>
						</div>
					</Col>
					<Col className="px-1">
						<div className="text-center">
							<img
								width="30px"
								height="30px"
								alt="..."
								src={require('assets/img/icons/booking.png')}
								className="mb-2"
							/>
							<br />
							<small>Booking</small>
						</div>
					</Col>
				</Row>
				<Container>
					<Nav tabs>
						<NavItem className="tab-gifd-card">
							<NavLink
								className={
									classnames({ active: activeTab === '1' }) +
									' tab_salon'
								}
								onClick={() => {
									toggle('1');
								}}
							>
								About
							</NavLink>
						</NavItem>
						<NavItem className="tab-gifd-card">
							<NavLink
								className={
									classnames({ active: activeTab === '2' }) +
									' tab_salon'
								}
								onClick={() => {
									toggle('2');
								}}
							>
								Review
							</NavLink>
						</NavItem>
					</Nav>
					<TabContent activeTab={activeTab} className="mt-4">
						<TabPane tabId="1" id="tab_about" style={{overflowX:'scroll'}}></TabPane>
						<TabPane tabId="2">
							<div className="mb-3 text-center">
								{review_stars}
							</div>
							<div id="list_link">

							</div>
							<Form onSubmit={handleSubmit(onSubmit)}>
								<FormFeedback id="result_review"></FormFeedback>
								<div className="text-center">
									<FormGroup tag="fieldset" className="mb-1">
										<ButtonGroup
											className="d-flex justify-content-between"
											size="lg"
										>
											{html_list_by_stars}
										</ButtonGroup>
									</FormGroup>
								</div>
								<FormGroup>
									<Input
										type="textarea"
										name="content"
										id="content"
										innerRef={register({
											required: true,
										})}
										placeholder={`Say something about ${salon?.companyName}`}
										required="required"
									/>
								</FormGroup>
								<Button
									className="btn btn-block"
									color="primary"
								>
									Submit
								</Button>
							</Form>
						</TabPane>
					</TabContent>
				</Container>
				<Social openSocial={openSocial} setOpenSocial = {setOpenSocial} data={social}/>
			</>
		);
	}
	return null;
}
