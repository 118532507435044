import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import DetailGiftCard from 'components/Modal/DetailGiftCard';

export default function NotifycationGiftCard({
	openNotyfiGift,
	setOpenNotyfiGift,
	status,
	giftcardid,
	dataImage
}) {
	let history = useHistory();
	const [openDetail, setShowDetail] = useState(false);
	useEffect(() => {
		openDetail && setOpenNotyfiGift(!openNotyfiGift)
	}, [openDetail])
	return (
		<>
			<Modal
				isOpen={openNotyfiGift}
				toggle={() => setOpenNotyfiGift(!openNotyfiGift)}
				centered={true}
			>
				<ModalBody>
					<div className="text-center mb-3">
						<img
							src={require(`assets/img/background/icons_${
								status ? 'win' : 'lose'
							}.png`)}
							alt={status ? 'win' : 'lose'}
							width={300}
						/>
					</div>
					<p className="text-center">
						{status
							? 'Your gift card has been sent to your friend'
							: 'The Credit Members is not enough.\n Please add more to member account to continue with this payment.'}
					</p>
					<Button
						onClick={() =>
							status
								? setShowDetail(!openDetail)
								: history.push('/giftcards')
						}
						color="primary"
						block
						className="mb-2"
					>
						{status
							? 'View Gift Card'
							: 'Continue with Credit Card'}
					</Button>
					<Button
						onClick={() =>
							status
								? history.push('/giftcards')
								: setOpenNotyfiGift(!openNotyfiGift)
						}
						block
						color="secondary"
					>
						{status ? 'Make New Gift Card' : 'Cancel'}
					</Button>
				</ModalBody>
			</Modal>
			<DetailGiftCard openDetail={openDetail} setShowDetail={setShowDetail} cardId={giftcardid}/>
		</>
	);
}
