import React from 'react';

// reactstrap components
import { Container } from 'reactstrap';
import {withRouter} from 'react-router-dom'
import CardStats from 'components/Headers/CardStats';

export default withRouter(function Header({
	isDisplayHeader = true,
	stats = false,
	title = '',
	history
}) {
	if (!isDisplayHeader) return null;
	return (
		<div className="header bg-white pb-3 pt-3 ">
			<Container fluid>
				<div className="header-body text-center">
					{stats ? (
						<CardStats />
					) : (
						<div className="clearfix">
							<img
								className="float-left"
								src={require('assets/img/icons/IC_Back.png')}
								width="30"
								onClick={() => history.goBack()}
								alt="Go Back"
							/>
							<h6 className="h2 text-dart d-inline-block mb-0">
								{title}
							</h6>
						</div>
					)}
				</div>
			</Container>
		</div>
	);
})
