import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';

export default function Social({ openSocial, setOpenSocial, data }) {
	return (
		<Modal
			isOpen={openSocial}
			toggle={() => setOpenSocial(!openSocial)}
			centered={true}
		>
			<ModalBody className="text-center">
				
				{data && data.map((item, index) => {
					if (item.channelUrl != '') {
						return (
							<div key={index} className="mb-3">
								<a href={item.channelUrl} target="_blank">
									<img className="img-thumbnail" width="200px" style={{height:'100px'}} src={require(`assets/svg/social/${item.channelName}.svg`)} alt={item.channelName} />
								</a>
							</div>
						)
					}
				}
				)}
			</ModalBody>
		</Modal>
	);
}
