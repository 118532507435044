// import Profile from 'views/Profile.jsx';
import Login from 'views/Login.jsx';
import Index from 'views/Index.jsx';
import MyCoupon from 'views/MyCoupon.jsx';
import GiftCard from 'views/GiftCard'
import GiftCardDetail from 'views/GiftCardDetail'
import AddCreditSuccess from 'views/AddCreditSuccess';
import Transaction from 'views/Transaction';
import TransactionDetail from 'views/TransactionDetail';
import LuckySpin from 'views/LuckySpin';
import HunSpin from 'views/HuntSpin';
import AddCredit from 'views/AddCredit';
import AboutSalon from 'views/AboutSalon'
import ReferralHistory from 'views/ReferralHistory'
import Profile from 'views/Profile'
import EditProfile from 'views/EditProfile'
import AppointmentHistory from 'views/AppointmentHistory'

var routes = [
	{
		path: '/',
		name: 'Dashboard',
		component: Index,
		layout: 'admin',
		// stats: true,
		isDisplayHeader: false,
		isDisplayNav: false
	},
	{
		path: '/profile',
		name: 'Profile',
		component: Profile,
		layout: 'admin',
		isDisplayNav: false,
		isDisplayHeader: false
	},
	{
		path: '/editprofile',
		name: 'Edit Profile',
		component: EditProfile,
		layout: 'admin',
		isDisplayNav: false,
	},
	{
		path: '/mycoupon',
		name: 'My Coupon',
		component: MyCoupon,
		layout: 'admin',
		isDisplayNav: false
	},
	{
		path: '/giftcards',
		name: 'Gift Card',
		component: GiftCard,
		layout: 'admin',
		isDisplayNav: false
	},
	{
		path: '/giftcarddetail',
		name: 'Gift Card Detail',
		component: GiftCardDetail,
		layout: 'admin',
		isDisplayNav: false
	},
	{
		path: '/transaction',
		name: 'Membership Card',
		component: Transaction,
		layout: 'admin',
		isDisplayNav: false
	},
	{
		path: '/transactiondetail',
		name: 'Transaction Details',
		component: TransactionDetail,
		layout: 'admin',
		isDisplayNav: false
	},
	{
		path: '/referralhistory',
		name: 'Referral History',
		component: ReferralHistory,
		layout: 'admin',
		isDisplayNav: false,
		isDisplayHeader: false,
	},
	{
		path: '/luckyspin',
		name: 'Lucky Spin',
		component: LuckySpin,
		layout: 'admin',
		isDisplayHeader: false,
		isDisplayNav: false
	},
	{
		path: '/huntspin',
		name: 'Hunt Spins',
		component: HunSpin,
		layout: 'admin',
		isDisplayNav: false,
		isDisplayHeader: false,
	},
	{
		path: '/addcredit',
		name: 'Add credit',
		component: AddCredit,
		layout: 'admin',
		isDisplayNav: false
	},
	{
		path: '/creditsuccess',
		name: 'Add Credit Success',
		component: AddCreditSuccess,
		layout: 'admin',
		isDisplayHeader: false
	},
	{
		path: '/aboutsalon',
		name: 'About Salon',
		component: AboutSalon,
		layout: 'admin',
		isDisplayHeader: false,
		isDisplayNav: false
	},
	{
		path: '/appointmenthistory',
		name: 'Appointment History',
		component: AppointmentHistory,
		layout: 'admin',
		isDisplayNav: false
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		layout: 'auth',
	}
];
export default routes;
