import React, { useState, useEffect } from 'react';

// reactstrap components
import {
	Card,
	Container,
	CardImgOverlay,
	CardImg,
	CardTitle,
} from 'reactstrap';
import { API, getDataCookie, logOut, isAuthenticated } from 'common';
import { Link, useHistory } from 'react-router-dom';
import SelectSalon from 'components/Modal/SelectSalon';

export default function Profile() {
	const [openSalon, setOpenSalon] = useState(false);
	const [dataSalon, setDataSalon] = useState([]);
	let history = useHistory();
	const redirectLogin = () => {
		if (!isAuthenticated()) {
			history.push('/login');
		}
	};
	let infor_customer = JSON.parse(localStorage.getItem('infor_customer'));

	const fecthInforCustomer = async () => {
		let response = await API.get(
			`customer/getDetailCustomer/${getDataCookie(
				process.env.REACT_APP_COOKIE_KEY
			)}`
		);
		let { payload } = response.data;
		fecthListSalon(payload.phone);
	};

	const fecthListSalon = async (phone) => {
		let response = await API.get(`customer/signInByPhone/${phone}`);
		let { payload } = response.data;
		if (payload.length !== 0) {
			setDataSalon(payload);
		}
	};

	useEffect(() => {
		fecthInforCustomer();
	}, []);


	return (
		<>
			<div className="header bg-white pb-3 pt-3 ">
				<Container fluid>
					<div className="header-body text-center">
						<div className="clearfix">
							<img
								className="float-left"
								src={require('assets/img/icons/IC_Back.png')}
								width="30"
								onClick={() => {
									window.history.back();
								}}
								alt="Go Back"
							/>
							<h6 className="h2 text-dart d-inline-block mb-0">
								Profile
							</h6>
							<p
								style={{ float: 'right' }}
								onClick={() => logOut(redirectLogin)}
							>
								Logout
							</p>
						</div>
					</div>
				</Container>
				<Container>
					<Card inverse>
						<CardImg
							width="100%"
							style={{ maxHeight: '100px' }}
							src={require('assets/img/card/card.png')}
							alt="Add Credit Card"
						/>
						<CardImgOverlay>
							<CardTitle>
								<div className="float-left">
									<h1
										style={{
											color: '#fff',
											fontWeight: '700',
											fontSize: '25px',
										}}
										className="text-uppercase"
									>
										{infor_customer?.customerName}
									</h1>
									<Link to="/editprofile">
										<span style={{ color: '#fff' }}>
											Edit Profile
										</span>
									</Link>
								</div>
								<img
									height={40}
									width={40}
									className="rounded-circle float-right"
									alt="..."
									src={
										infor_customer && infor_customer.avatar && infor_customer.avatar != ''
											? infor_customer.avatar : '/static/media/team-4-800x800.23007132.jpg'
									}
								/>
							</CardTitle>
						</CardImgOverlay>
					</Card>
				</Container>
				<Container className="mt-4">
					{dataSalon.length > 1 ? (
						<>
							<div
								style={{ height: '50px' }}
								onClick={() => setOpenSalon(!openSalon)}
							>
								<img
									height={40}
									width={40}
									className="float-left"
									alt="..."
									src={require('assets/img/profile/IC_switch.png')}
								/>
								<div className="ml-5">
									<h3>Switch Salon</h3>
									<span>
										{infor_customer &&
											infor_customer.shopName}
									</span>
								</div>
							</div>
							<hr />
						</>
					) : (
						''
					)}
				</Container>
			</div>
			<SelectSalon
				openSalon={openSalon}
				setOpenSalon={setOpenSalon}
				dataSalon={dataSalon}
				isSwitch="true"
			/>
		</>
	);
}
