import React, { useState } from 'react';
// reactstrap components
import { Helmet } from 'react-helmet';
import {
	Container,
	Form,
	FormGroup,
	Label,
	Input,
	Row,
	Col,
	Button,
	ButtonGroup,
	FormFeedback,
} from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import InforCard from 'components/Common/InforCard';
import constant from 'constant';
import { API, getDataCookie } from 'common';
import { useHistory } from 'react-router-dom';

var count = 0;
export default function AddCredit() {
	const [disableAddCredit, setDisableAddCredit] = useState(true);
	// const [count, setCount] = useState(0);
	const [dataForm, setDataForm] = useState({
		balance: '',
		cardName: '',
		cardNumber: '',
		cvv: '',
		expireDate: '',
		paymentType: '',
	});
	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		errors,
		setError,
		control,
		onChange,
	} = useForm();

	const history = useHistory();

	const onSubmit = async (data) => {
		try {
			var flag_isset_error = false;

			var card_number_format = '';
			if (!data.cardNumber.match(/^\d{16}$/)) {
				card_number_format =
					'Please enter Card Number the correct format';
				flag_isset_error = true;
			}
			document.getElementById(
				'card_number_format'
			).textContent = card_number_format;

			var expires_date_format = '';
			if (!data.expireDate.match(/^(0[1-9]|1[1-2])\/\d{2}$/)) {
				expires_date_format =
					'Please enter Expired Date the correct format';
				flag_isset_error = true;
			}
			document.getElementById(
				'expires_date_format'
			).textContent = expires_date_format;

			var cvv_format = '';
			if (!data.cvv.match(/^\d{3}$/)) {
				cvv_format = 'Please enter CVV the correct format';
				flag_isset_error = true;
			}
			document.getElementById('cvv_format').textContent = cvv_format;

			if (!flag_isset_error) {
				data.balance = parseInt(data.balance.substr(1));
				data.paymentType = parseInt(data.paymentType);
				data.customerId = parseInt(
					getDataCookie(process.env.REACT_APP_COOKIE_KEY)
				);
				// convert date
				data.expireDate =
					data.expireDate.substr(0, 2) + data.expireDate.substr(-2);
				const response = await API.post('payment/addCredit', {
					payload: data,
				});
				let { code } = response.data;
				if (code == 200) {
					let response = await API.get(
						`customer/getDetailCustomer/${getDataCookie(
							process.env.REACT_APP_COOKIE_KEY
						)}`
					);
					let { payload } = response.data;
					let infor_customer = JSON.parse(
						localStorage.getItem('infor_customer')
					);
					infor_customer.balance = payload.balance;
					localStorage.setItem(
						'infor_customer',
						JSON.stringify(infor_customer)
					);
					history.push('/creditsuccess');
				}
			}
		} catch (error) {
			const { data } = error.response;
			document.getElementById('add_credit_failed').innerHTML =
				data.message;
			// setError('addCreditCard', data.message);
		}
	};

	const reset_list_balance = () => {
		var radioButtons = document.getElementsByName('radio_card');
		for (var i = 0; i < radioButtons.length; i++) {
			if (radioButtons[i].checked) radioButtons[i].checked = false;
		}
	};
	const reload_list_card_value = (e) => {
		reset_list_balance();
		if (e.target.value.indexOf('$') == -1) {
			e.target.value = '$' + e.target.value;
		}
	};

	const double_click_balance = () => {
		// increment count
		count++;
		setTimeout(() => {
			// listen for double clicks
			if (count === 2) {
				reset_list_balance();
				document.getElementById('balance').value = '';
			}
			// reset count
			count = 0;
		}, 250); // 250 ms
	};

	return (
		<div className="pt-2" className="form_input_card">
			<Helmet title="Add Credit" />
			<InforCard prop_class="mx-3" />
			<Container>
				<Form
					className="form my-3"
					onSubmit={handleSubmit(onSubmit)}
					autoComplete="off"
				>
					<FormFeedback>
						<h2
							className="text-center"
							style={{ color: 'red' }}
							id="add_credit_failed"
						></h2>
					</FormFeedback>
					<h3>Credit Amount</h3>
					<Row>
						<div className="col-12">
							<FormGroup>
								<Input
									type="text"
									step="any"
									min={constant.MIN_AMOUNT}
									name="balance"
									id="balance"
									placeholder="$0"
									required="required"
									defaultValue={getValues('balance')}
									innerRef={register({
										required: true,
									})}
									onClick={(event) => {
										event.target.value = '';
									}}
									onChange={(event) =>
										reload_list_card_value(event)
									}
									pattern="[$][1-9]+[0]*"
								/>
							</FormGroup>
						</div>
					</Row>
					<Row>
						<div className="col-12">
							<FormGroup tag="fieldset">
								<ButtonGroup
									className="d-flex justify-content-between"
									size="lg"
								>
									{constant.CARD_VALUE.map((i, k) => (
										<FormGroup
											check
											style={{ paddingLeft: '0' }}
											key={k}
										>
											<Input
												type="radio"
												name="radio_card"
												id={`radio_card_${i}`}
												onClick={() => {
													setValue(
														'balance',
														`$${i}`
													);
													double_click_balance();
												}}
												style={{ visibility: 'hidden' }}
											/>
											<Label
												for={`radio_card_${i}`}
												className="radio_card_label btn-effect-click"
											>
												{`$${i}`}
											</Label>
										</FormGroup>
									))}
								</ButtonGroup>
							</FormGroup>
						</div>
					</Row>
					<h3>Payment Method</h3>
					<FormGroup className="form-customize mt-2 mb-0">
						<Input
							type="select"
							name="paymentType"
							id="paymentType"
							innerRef={register({
								required: true,
							})}
							required="required"
							defaultValue=""
							className="form-customize-input"
							style={{ paddingLeft: '0.5rem' }}
						>
							<option value="0">Credit Card</option>
						</Input>
						<Label
							for="paymentType"
							className="form-customize-label text-uppercase"
						>
							Select Payment Method
							<span className="text-red">*</span>
						</Label>
					</FormGroup>
					<p className="mb-1 mt-1">Your Card Details</p>
					<FormGroup className="form-customize mt-2 mb-0">
						<Input
							type="input"
							name="cardName"
							id="cardName"
							required="required"
							placeholder="Cardholder Name"
							innerRef={register({
								required: true,
							})}
							required="required"
							className="form-customize-input form-control"
						/>
						<Label
							for="cardName"
							className="form-customize-label text-uppercase"
						>
							Cardholder Name
							<span className="text-red">*</span>
						</Label>
					</FormGroup>
					<FormGroup className="form-customize mt-2 mb-0">
						<Controller
							type="tel"
							as={InputMask}
							control={control}
							mask="9999999999999999"
							className="form-customize-input form-control"
							placeholder="Card Number"
							name="cardNumber"
							defaultValue=""
							required="required"
							rules={{
								required: true,
							}}
						/>
						<Label
							for="cardNumber"
							className="form-customize-label text-uppercase"
						>
							Card Number
							<span className="text-red">*</span>
						</Label>
					</FormGroup>
					<FormFeedback
						className="mt-0"
						id="card_number_format"
					></FormFeedback>
					<Row form>
						<Col xs={6}>
							<FormGroup className="form-customize mt-2 mb-0">
								<Controller
									type="tel"
									as={InputMask}
									control={control}
									mask="99/99"
									className="form-customize-input form-control"
									placeholder="MM/YY"
									name="expireDate"
									defaultValue=""
									required="required"
									rules={{
										required: true,
									}}
								/>
								<Label
									for="expireDate"
									className="form-customize-label text-uppercase"
								>
									Expired Date
									<span className="text-red">*</span>
								</Label>
							</FormGroup>
							<FormFeedback
								className="mt-0"
								id="expires_date_format"
							></FormFeedback>
						</Col>
						<Col xs={6}>
							<FormGroup className="form-customize mt-2 mb-0">
								<Controller
									type="tel"
									as={InputMask}
									control={control}
									mask="999"
									className="form-customize-input form-control"
									placeholder="CVV"
									name="cvv"
									defaultValue=""
									rules={{
										required: true,
									}}
									required="required"
								/>
								<Label
									for="cvv"
									className="form-customize-label text-uppercase"
								>
									CVV
									<span className="text-red">*</span>
								</Label>
							</FormGroup>
							<FormFeedback
								className="mt-0"
								id="cvv_format"
							></FormFeedback>
						</Col>
					</Row>
					<Button
						color="primary"
						type="submit"
						className="btn btn-block btn-primary mt-2"
					>
						Add Credit
					</Button>
				</Form>
			</Container>
		</div>
	);
}
