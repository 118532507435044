import React from 'react';
import { Helmet } from 'react-helmet';
import Header from 'components/Headers/Header.jsx';
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx';
import routes from 'routes.js';

const withHeader = (
	Component,
	{ title, stats, isDisplayHeader, isDisplayNav }
) => (props) => {
	const userInfo = { name: 'admin' }; //getUserInfo();
	const getBrandText = () => {
		const { location } = props;
		for (let i = 0; i < routes.length; i++) {
			if (
				location.pathname.indexOf(routes[i].layout + routes[i].path) !==
				-1
			) {
				return routes[i].name;
			}

			if (routes[i].collapse && routes[i].views.length !== 0) {
				let founded = routes[i].views.find(
					(sub) =>
						location.pathname.indexOf(
							routes[i].layout + sub.path
						) !== -1
				);
				if (founded) return founded.name;
			}
		}
		return 'Brand';
	};
	return (
		<>
			<Helmet title={title || 'Chưa có title'} />
			{isDisplayNav && (
				<AdminNavbar
					{...props}
					userInfo={userInfo}
					brandText={getBrandText()}
				/>
			)}
			<Header
				title={title || 'Chưa có title'}
				stats={stats}
				isDisplayHeader={isDisplayHeader}
			/>
			<Component {...props} />
		</>
	);
};
export default withHeader;
