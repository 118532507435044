import React, { useState, useEffect } from 'react';
import PinInput from 'react-pin-input';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import constant from 'constant';
import {
	API,
	setCookieAuthenticate,
	initHeader,
	setHeader,
	logOut,
	isAuthenticated,
} from 'common';
// reactstrap components
import { Button, Modal, CardBody, FormFeedback } from 'reactstrap';
import BackgroundSlider from 'react-background-slider';

function importAll(r) {
	return r.keys().map(r);
}

const imagesSlide = importAll(
	require.context('assets/img/slide', false, /\.(png|jpe?g)$/)
);

let element;
export default function PhoneVerifyCustomer() {
	const [phoneVerify, setPhoneVerify] = useState(false);
	const [pincode, setPinCode] = useState(null);
	const [counter, setCounter] = useState(constant.SETTING_TIME_EXPIRE_CODE);
	const { errors, setError } = useForm();

	const { api_key, id } = useParams();
	setHeader(api_key);
	var customerId = parseInt(id);
	const verifyPhone = async () => {
		try {
			if (!pincode) {
				setError('verify', { type: 'length' });
				return false;
			}
			let response = await API.post('customer/confirmOTP', {
				payload: { customerId, verifyCode: pincode },
			});
			if (response.status === 200) {
				save_data_customer(response.data.payload);
			}
		} catch (error) {
			const { data } = error.response;
			data.code === 1003 && setError('verify', { type: 'verify_fail' });
			element.clear();
		}
	};

	let history = useHistory();
	const redirectLogin = () => {
		if (!isAuthenticated()) {
			history.push('/login');
		}
	};

	const checkValidateOtp = async () => {
		const response = await API.get(`customer/checkValidateOtp?customerId=${customerId}`);
		if (response.data.code === 200) {
			save_data_customer(response.data.payload);
		}
		else {
			setPhoneVerify(true)
		}
		
	};
	const save_data_customer = (data_customer) => {
		setCookieAuthenticate(
			customerId,
			parseInt(process.env.REACT_APP_EXPIRES_COOKIE)
		);
		initHeader();
		localStorage.setItem(
			'infor_customer',
			JSON.stringify(data_customer)
		);

		window.location.href = '/';
		let congratulation_flag = false;
		localStorage.setItem(
			'congratulation_flag',
			congratulation_flag
		);
	}
	useEffect(() => {
		checkValidateOtp();
		let timer;
		if (phoneVerify) {
			timer =
				counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		}
		return () => timer && clearInterval(timer);
	}, [counter, phoneVerify]);
	return (
		<>
			{
				phoneVerify?
				<BackgroundSlider
					images={imagesSlide}
					duration={constant.SLIDE_DURATION}
					transition={2}
				/>
				: ''
			}
			<Modal className="fixed-bottom" size="lg" isOpen={phoneVerify}>
				<CardBody>
					<h2>Phone Verification</h2>
					<p>
						Verification code has been sent to your phone. Enter the
						code to sign-in
					</p>
					<p className="text-center mb-1">
						{counter === 0
							? 'Your code has expired'
							: `Your code will expire after ${counter} seconds`}
					</p>
					<PinInput
						length={4}
						type="numeric"
						ref={(n) => (element = n)}
						inputMode="number"
						style={{
							padding: '10px',
							textAlign: 'center',
							display: 'flex',
							justifyContent: 'space-between',
						}}
						inputStyle={{
							fontSize: '32px',
							fontWeight: 'bolder',
							borderColor: '#8898aa',
							width: '60px',
							height: '60px',
						}}
						onComplete={(value) => setPinCode(value)}
						autoSelect={true}
					/>
					{errors.verify?.type === 'verify_fail' && (
						<FormFeedback className="text-center mb-1">
							Your verification code is incorrect
						</FormFeedback>
					)}
					{errors.verify?.type === 'length' && (
						<FormFeedback className="text-center mb-1">
							Your verification code must be 4 characters long
						</FormFeedback>
					)}
					<div className="text-center mb-3">
						<Button
							className="btn btn-block"
							color="primary"
							type="button"
							onClick={verifyPhone}
						>
							Verification
						</Button>
					</div>
					<p className="text-center">
						Incorret phone number?{' '}
						<a
							onClick={() => {
								logOut(redirectLogin);
							}}
						>
							Try back
						</a>
					</p>
				</CardBody>
			</Modal>
		</>
	);
}
