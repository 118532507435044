import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import constant from 'constant';
import { API, setHeader } from 'common';

// reactstrap components
import {
	Button,
	FormGroup,
	Form,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	FormFeedback,
	Modal,
	ModalBody,
} from 'reactstrap';
import SelectSalon from 'components/Modal/SelectSalon';
import PhoneVerify from 'components/Modal/PhoneVerify';
export default function SignIn({ loginState, setLogin, setRegister }) {
	const { handleSubmit, control, errors, setError } = useForm();
	const [phoneVerify, setPhoneVerify] = useState(false);
	const [dataOTP, setdataOTP] = useState(null);
	const [phone, setPhone] = useState(null);
	const [openSalon, setOpenSalon] = useState(false);
	const [customerId, setCustomerId] = useState(null);
	const [dataSalon, setDataSalon] = useState([]);

	const onSubmit = async (data) => {
		try {
			let unmaskphone = data.phone.replace(/[^\d]/g, '');
			setPhone(unmaskphone);
			let response = await API.get(
				`customer/signInByPhone/${unmaskphone}`
			);
			let { payload } = response.data;
			if (payload.length !== 0) {
				setLogin(!loginState);
				if (payload.length === 1) {
					let { apiKey, id } = payload[0];
					setHeader(apiKey);
					localStorage.setItem(
						'shop',
						JSON.stringify(payload[0])
					);
					setdataOTP({ phone: unmaskphone, shopId: id });
					await sendOtp({
						phone: unmaskphone,
						shopId: id,
					});
					setPhoneVerify(!phoneVerify);
				} else {
					setDataSalon(payload);
					setOpenSalon(!openSalon);
				}
			}
			if (payload.length === 0) {
				setError('phone', { type: 'error_phone' });
			}
		} catch (error) {
			const { data } = error.response;
			data.error &&
				setError('account', data.error.name, data.error.message);
		}
	};

	const sendOtp = async (data) => {
		let response = await API.post(`customer/getOTPByShop`, null, {
			params: data || dataOTP,
		});
		let { payload } = response.data;
		setCustomerId(payload.customerId);
	};
	return (
		<>
			<Modal
				className="fixed-bottom"
				size="lg"
				isOpen={loginState}
				toggle={() => setLogin(!loginState)}
			>
				<ModalBody>
					<h2>Sign in</h2>
					<p className="text-muted">
						Please fill your information to sign-in
					</p>
					<Form role="form" onSubmit={handleSubmit(onSubmit)}>
						<FormGroup>
							<InputGroup className="input-group-alternative">
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										<img
											src={require('assets/svg/ic_phone.svg')}
											alt="Phone Number"
										/>
									</InputGroupText>
								</InputGroupAddon>
								<Controller
									as={InputMask}
									control={control}
									mask={constant.MASK_PHONE_INPUT}
									className="form-control"
									defaultValue=""
									type="tel"
									placeholder="Phone Number"
									name="phone"
									rules={{
										required: true,
										pattern: /\([0-9]{3}\) [0-9]{3}-[0-9]{4}/,
									}}
								/>
							</InputGroup>
							{errors.phone?.type === 'required' && (
								<FormFeedback>
									Your input is required
								</FormFeedback>
							)}
							{errors.phone?.type === 'pattern' && (
								<FormFeedback>
									Please enter the correct format
								</FormFeedback>
							)}
							{errors.phone?.type === 'error_phone' && (
								<FormFeedback>
									Your number phone does not exits.
								</FormFeedback>
							)}
						</FormGroup>
						{errors.account && (
							<FormFeedback>
								{errors.account.message}
							</FormFeedback>
						)}
						<div className="text-center">
							<Button
								className="btn btn-block"
								color="primary"
								type="submit"
							>
								Continue
							</Button>
						</div>
					</Form>
				</ModalBody>
			</Modal>
			<SelectSalon
				openSalon={openSalon}
				setOpenSalon={setOpenSalon}
				setLogin={setLogin}
				phoneVerify={phoneVerify}
				setPhoneVerify={setPhoneVerify}
				dataSalon={dataSalon}
				resend={sendOtp}
				dataOTP={dataOTP}
				phone={phone}
				customerId={customerId}
				setRegister={setRegister}
			/>
			{/* <PhoneVerify
				phoneVerify={phoneVerify}
				setRegister={setRegister}
				setPhoneVerify={setPhoneVerify}
				customerId={customerId}
				type="login"
				resend={sendOtp}
				setLogin={setLogin}
				phone={phone}
			/> */}
		</>
	);
}
