import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
// reactstrap components
import {
	Card,
	Container,
	Row,
	CardImg,
	Dropdown,
	DropdownMenu,
	DropdownToggle,
	DropdownItem,
	CardImgOverlay,
	Button,
	Col,
} from 'reactstrap';

import Slider from 'react-slick';
import Congratulation from 'components/Modal/Congratulation';
import { API, getDataCookie, logOut, isAuthenticated } from 'common';
import CouponDetail from 'components/Modal/CouponDetail';
import SelectSalon from 'components/Modal/SelectSalon';

export default function Dashboard() {
	const [openCongratulation, setOpenCongratulation] = useState(true);
	const [listPromotions, setListPromotions] = useState([]);
	const [inforCustomer, setInforCustomer] = useState(null);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [listBanner, setListBanner] = useState([]);
	const [openCoupon, setOpenCoupon] = useState(false);
	const [couponId, setCouponId] = useState(null);
	const [openSalon, setOpenSalon] = useState(false);
	const [dataSalon, setDataSalon] = useState([]);

	// dropdown
	const toggle = () => setDropdownOpen((prevState) => !prevState);

	// Promotions
	const fecthPromotions = async () => {
		const response = await API.post(
			`customer/getAllPromotions/${getDataCookie(
				process.env.REACT_APP_COOKIE_KEY
			)}`
		);
		let { payload } = response.data;
		if (payload.length !== 0) {
			setListPromotions(payload);
		}
	};

	// banner
	const fecthBanner = async () => {
		const response = await API.get(`shop/getAllBannerByShop`);
		let { payload } = response.data;
		if (payload.length !== 0) {
			setListBanner(payload);
		}
	};

	useEffect(() => {
		fecthBanner();
		fecthPromotions();
		var infor_customer = JSON.parse(localStorage.getItem('infor_customer'));
		setInforCustomer(infor_customer);
		fecthListSalon(infor_customer.phone);
	}, []);

	let history = useHistory();
	const redirectLogin = () => {
		if (!isAuthenticated()) {
			history.push('/login');
		}
	};

	const fecthListSalon = async (phone) => {
		let response = await API.get(`customer/signInByPhone/${phone}`);
		let { payload } = response.data;
		if (payload.length !== 0) {
			setDataSalon(payload);
		}
	};

	var html_siler_promotion = listPromotions.map((item) => {
		return (
			<Card inverse key={item.id}>
				<CardImg
					width="100%"
					// height="180px"
					src={item.imageCoupon}
					alt="Promotion"
				/>
				<CardImgOverlay style={{ top: '7em' }}>
					<Button
						className="btn btn-lock"
						onClick={() => {
							setCouponId(item.id);
							setOpenCoupon(!openCoupon);
						}}
					>
						Get Coupon
					</Button>
				</CardImgOverlay>
			</Card>
		);
	});

	const settings_slide_announcement = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		// autoplay: true,
		// autoplaySpeed: 10000,
	};

	const settings_slide_promations = {
		className: 'center',
		centerMode: true,
		// infinite: true,
		centerPadding: '20px',
		slidesToShow: 1,
		speed: 500,
		autoplay: true,
		arrows: false,
		autoplaySpeed: 5000,
	};

	const banner = listBanner.map((item) => {
		if (item.type == 1) {
			return (
				<img
					key={item.id}
					src={item.urlImage}
					alt={'banner_' + item.id}
					style={{ width: '100%'}}
					className="rounded"
				/>
			);
		} else {
			return (
				<iframe
					key={item.id}
					height="250"
					src={item.urlImage}
					frameborder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
				></iframe>
			);
		}
	});
	return (
		<>
			<Helmet title="Home" />
			{localStorage.getItem('congratulation_flag') === true ? (
				<Congratulation
					openCongratulation={openCongratulation}
					setOpenCongratulation={setOpenCongratulation}
				/>
			) : null}
			<div className="bg-header-home">
				<Container>
					<Row style={{ paddingTop: '20px' }}>
						<div className="col-6">Welcome back,</div>
						<div className="col-6 text-right">
							<Dropdown isOpen={dropdownOpen} toggle={toggle}>
								<DropdownToggle nav>
									<img
										height={40}
										width={40}
										className="rounded-circle float-left mr-1"
										alt="..."
										src={
											inforCustomer &&
											inforCustomer.avatar &&
											inforCustomer.avatar != ''
												? inforCustomer.avatar
												: '/static/media/team-4-800x800.23007132.jpg'
										}
									/>
								</DropdownToggle>
								<div
									style={{
										top: '30px',
										position: 'relative',
									}}
								>
									<DropdownMenu
										className="dropdown-menu-arrow"
										right
									>
										<Link to="/profile">
											<DropdownItem>
												<i
													className="fa fa-user"
													aria-hidden="true"
												/>
												<span>Profile</span>
											</DropdownItem>
										</Link>
										{dataSalon.length > 1 ? (
											<DropdownItem
												onClick={() =>
													setOpenSalon(!openSalon)
												}
											>
												<i className="fa fa-minus-square-o" />
												<span>Switch Salon</span>
											</DropdownItem>
										) : (
											''
										)}
										<Link to="/appointmenthistory">
											<DropdownItem>
												<i className="fa fa-calendar-check-o" />
												<span>Appointment History</span>
											</DropdownItem>
										</Link>
										<DropdownItem
											onClick={() =>
												logOut(redirectLogin)
											}
										>
											<i
												className="fa fa-sign-out"
												aria-hidden="true"
											/>
											<span>Logout</span>
										</DropdownItem>
									</DropdownMenu>
								</div>
							</Dropdown>
						</div>
					</Row>
					<Row className="text-center" style={{ paddingTop: '20px' }}>
						<Col>
							<Link to="/luckyspin" className="text-white">
								<div className="menu-home">
									<img
										alt="..."
										src={require('assets/img/home/lucky_spin.svg')}
									/>
								</div>
								<br />
								Lucky Spin
							</Link>
						</Col>
						<Col>
							<Link to="/aboutsalon" className="text-white">
								<div className="menu-home">
									<img
										alt="..."
										src={require('assets/img/home/coupon.svg')}
									/>
								</div>
								<br />
								About Salon
							</Link>
						</Col>
						<Col>
							<Link to="/giftcards" className="text-white">
								<div className="menu-home">
									<img
										alt="..."
										src={require('assets/img/home/gift_card.svg')}
									/>
								</div>
								<br />
								Gift Card
							</Link>
						</Col>
						<Col>
							<Link to="/addcredit" className="text-white">
								<div className="menu-home">
									<img
										alt="..."
										src={require('assets/img/home/top_up.svg')}
									/>
								</div>
								<br />
								Top up
							</Link>
						</Col>
					</Row>
					<div
						className="mt-3"
						style={{
							borderRadius: '10px',
							border: '2px solid #fff',
						}}
					>
						<Link to="/transaction" className="text-white">
							<Card
								style={{
									background: `url(${require('assets/img/card/card.png')}) no-repeat center center`,
									backgroundSize: 'cover',
									borderRadius: '10px',
								}}
							>
								<Row className="m-0">
									<div className="col-6 text-left">
										<h5
											style={{ color: '#FFF' }}
											className="mb-0 mt-1"
										>
											No.
											{inforCustomer &&
												inforCustomer.cardNumber}
										</h5>
										<h3
											style={{
												color: '#dcd03f',
												fontWeight: '600',
												fontSize: '18px',
											}}
											className="text-uppercase"
										>
											{inforCustomer &&
												inforCustomer.levelMembership}
										</h3>
									</div>
									<div className="col-6 text-right">
										<h3
											style={{
												color: '#FFF',
												fontWeight: '700',
											}}
											className="text-uppercase"
										>
											{inforCustomer &&
												inforCustomer.shopName}
										</h3>
									</div>
								</Row>
							</Card>
						</Link>
					</div>
				</Container>
			</div>
			<Container className="box-radius mb-2" fluid>
				<div className="mt-3">
					<span className="title-silder">
						Announcment from the salon
					</span>
				</div>
				<div className="mt-2 text-center">
					<Slider {...settings_slide_announcement}>{banner}</Slider>
				</div>
				<div className="mb-2" style={{ marginTop: '2.1rem' }}>
					<span className="title-silder">Promotions</span>
				</div>
				<div className="mb-5">
					<Slider
						{...settings_slide_promations}
						className="custom__promotion"
					>
						{html_siler_promotion}
					</Slider>
				</div>
				<div
					className="text-center fixed-bottom px-3 pb-2"
					style={{ backgroundColor: '#fff' }}
				>
					<a
						href={inforCustomer && inforCustomer.linkBooking}
						className="btn btn-block btn-primary"
						target="_blank"
						rel="noopener noreferrer"
					>
						Book Appointment
					</a>
				</div>
			</Container>
			<CouponDetail
				couponId={couponId}
				openCoupon={openCoupon}
				setOpenCoupon={setOpenCoupon}
			/>
			<SelectSalon
				openSalon={openSalon}
				setOpenSalon={setOpenSalon}
				dataSalon={dataSalon}
				isSwitch="true"
			/>
		</>
	);
}
