import React, { useState } from 'react';
import { setHeader, getDataCookie } from 'common';
import { Modal, ModalBody, Media } from 'reactstrap';
import Select, { components } from 'react-select';
import PhoneVerify from 'components/Modal/PhoneVerify';
export default function SelectSalon({
	openSalon,
	setOpenSalon,
	phoneVerify,
	setPhoneVerify,
	dataSalon,
	resend,
	phone,
	customerId,
	setLogin,
	setRegister,
	isSwitch = false
}) {
	const [salon, setSalon] = useState(null);
	const handleChange = (selectedOption) => {
		var api_key = getDataCookie(process.env.REACT_APP_API_X_KEY);
		setHeader(selectedOption.value);
		setSalon(salon);
		let shops = dataSalon.filter((i) => i.apiKey === selectedOption.value);
		if (shops.length !== 0) {
			localStorage.setItem(
				'shop',
				JSON.stringify(shops[0])
			);
			if (isSwitch) {
				let infor_customer = JSON.parse(localStorage.getItem('infor_customer'));
				infor_customer.shopName = shops[0].companyName;
				infor_customer.linkBooking = infor_customer.linkBooking.replace(api_key, selectedOption.value);
				localStorage.setItem(
					'infor_customer',
					JSON.stringify(infor_customer)
				);
				
				window.location.reload();
			}
			resend({ phone: phone, shopId: shops[0].id });
		}
		setOpenSalon(!openSalon);
		setPhoneVerify(!phoneVerify);
	};

	const ValueContainer = ({ children, ...props }) =>
		components.ValueContainer && (
			<components.ValueContainer {...props}>
				{!!children && (
					<img
						src={require('assets/svg/search.svg')}
						alt="Search"
						style={{ position: 'absolute', left: 13 }}
					/>
				)}
				{children}
			</components.ValueContainer>
		);
	return (
		<>
			<Modal
				isOpen={openSalon}
				toggle={() => setOpenSalon(!openSalon)}
				centered={true}
			>
				<ModalBody>
					<h1 className="font-weight-bold mb-2">Select Salon</h1>
					<Select
						value={salon}
						onChange={handleChange}
						options={dataSalon.map((i) => ({
							value: i.apiKey,
							label: i.companyName,
						}))}
						className="mb-4 input-group-alternative"
						isSearchable={true}
						components={{
							IndicatorSeparator: () => null,
							ValueContainer,
						}}
						placeholder="Salon"
						styles={{
							control: (base) => ({
								...base,
								height: 46,
								minHeight: 46,
								border: '1px solid #adb5bd',
								color: '#adb5bd',
							}),
							valueContainer: (base) => ({
								...base,
								paddingLeft: 37,
								color: '#adb5bd',
							}),
							placeholder: (defaultStyles) => {
								return {
									...defaultStyles,
									color: '#adb5bd',
									marginLeft: '10px',
								};
							},
						}}
					/>
					{dataSalon.map((item, k) => (
						<div key={k}>
							<Media
								className="mb-1"
								onClick={() =>
									handleChange({
										value: item.apiKey,
										label: item.companyName,
									})
								}
							>
								<Media left className="mr-3">
									<Media
										object
										src={
											'data:image/jpg;base64,' +
											item.avatar
										}
										alt="Generic placeholder image"
										width="64"
										height="64"
										className="rounded"
									/>
								</Media>
								<Media body>
									<Media className="mb-2" heading>
										{item.companyName}
									</Media>
									<p
										style={{
											fontSize: '0.8rem',
											lineHeight: '1.4',
										}}
									>
										Address: {item.address}
										<br /> Phone: {item.phone}
									</p>
								</Media>
							</Media>
							{dataSalon.length - 1 !== k && (
								<hr className="my-1" />
							)}
						</div>
					))}
				</ModalBody>
			</Modal>
			<PhoneVerify
				phoneVerify={phoneVerify}
				setPhoneVerify={setPhoneVerify}
				customerId={customerId}
				type="login"
				setLogin={setLogin}
				resend={resend}
				setRegister={setRegister}
				phone={phone}
			/>
		</>
	);
}
