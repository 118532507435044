import React, { useState, useEffect } from 'react';

// reactstrap components
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Card, CardBody, Row } from 'reactstrap';

import InforCard from 'components/Common/InforCard';
import { API, getDataCookie } from 'common';

export default function Transaction() {
	const [listTransaction, setListTransaction] = useState([]);

	const fecthListTransaction = async () => {
		const response = await API.get(
			`customer/getHistoryTransaction/${getDataCookie(
				process.env.REACT_APP_COOKIE_KEY
			)}`
		);
		let { payload } = response.data;
		if (payload && payload.length != 0) {
			var sorted_transaction = payload.transactions.sort(function(a,b) {
				return b.id - a.id;
			})
			setListTransaction(sorted_transaction);
		}
	};

	useEffect(() => {
		fecthListTransaction();
	}, []);

	const html_list_transaction = listTransaction.map((item) => {
		let color = 'green';
		let plus = '+';
		if (item.giftCardId != 0) {
			color = 'red';
			plus = '-';
		}
		var path_name = '';
		if (item.giftCardId != 0) {
			path_name = "/transactiondetail";
		}
		return (
			<Link to={{
				pathname: `${path_name}`,
				state: { 
					cardId: item.giftCardId,
					timePayment: item.timePayment
				}
				}}
				key={item.id}
			>
				<Card
					style={{ marginBottom: '20px' }}
				>
					<CardBody className="p-3" style={{height: '80px'}}>
						<div className="float-left">
							<h3
								style={{
									fontWeight: '600',
									fontSize: '18px',
									color:'#085188'
								}}
							>
								{item.title}
							</h3>
							<span style={{color:'#adb5bd'}}>{item.timePayment}</span>
						</div>
						<div className="float-right">
							<h3
								style={{
									color: `${color}`,
									fontWeight: '700',
									paddingTop: '20px',
								}}
							>
								{plus}${item.price}
							</h3>
						</div>
					</CardBody>
				</Card>
			</Link>
		);
	});

	return (
		<>
			<Helmet title="Membership Card" />
			<Container
				className="pt-3"
				style={{ backgroundColor: '#e9ecef'}}
			>
				<InforCard prop_class="" />
				<h2 className="mt-4">Transaction History</h2>
				<div className="pb-5">
					{listTransaction.length > 0
						? html_list_transaction
						: 'Not available list tracsaction!'}
				</div>
				<div className="fixed-bottom px-3 pb-2">
					<Link to="/addcredit" className="btn btn-block btn-primary">
						Add Credit
					</Link>
				</div>
			</Container>
		</>
	);
}
