import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

export default function SpinSuccess({ bonus, spinSuccess, setSpinSuccess }) {
	return (
		<Modal
			isOpen={spinSuccess}
			toggle={() => setSpinSuccess(!spinSuccess)}
			centered={true}
		>
			<ModalBody>
				<div className="text-center">
					<img
						width="100%"
						src={require('assets/img/spin/congratulation.jpg')}
						alt="spin fail"
					/>
				</div>
				<b className="text-center mt-4">
					You have won a <span style={{color:'blue'}}>${bonus}</span> the prize value will be adding to
					your membership card
				</b>
				<Button
					color="primary"
					size="lg"
					block
					onClick={() => setSpinSuccess(!spinSuccess)}
				>
					Countinue With Spin
				</Button>
				<Link
					to="/transaction"
					className="btn btn-block"
					style={{ color: 'black' }}
				>
					Membership Card
				</Link>
			</ModalBody>
		</Modal>
	);
}
