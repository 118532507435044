import React, { useState, useEffect } from 'react';

// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	Container,
	Label,
	ButtonGroup,
	FormFeedback,
} from 'reactstrap';
import { API, getDataCookie, isAuthenticated } from 'common';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useForm, Controller } from 'react-hook-form';
import constant from 'constant';
import InputMask from 'react-input-mask';

export default function EditProfile() {
	const [inforCustomer, setinforCustomer] = useState(null);
	const [imgBase64, setImgBase64] = useState(null);
	const [disableButton, setDisableButton] = useState(false);
	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		control,
		errors,
		setError,
	} = useForm();

	let infor_customer = JSON.parse(localStorage.getItem('infor_customer'));

	const fecthInforCustomer = async () => {
		let response = await API.get(
			`customer/getDetailCustomer/${getDataCookie(
				process.env.REACT_APP_COOKIE_KEY
			)}`
		);
		let { payload } = response.data;
		if (payload.birthday != '') {
			var dob_convert = new Date(payload.birthday);
			dob_convert =
				(dob_convert.getMonth() + 1).toString().padStart(2, '0') +
				'/' +
				dob_convert.getDate().toString().padStart(2, '0');
			setValue('dob', dob_convert);
		}
		setinforCustomer(payload);
	};

	useEffect(() => {
		fecthInforCustomer();
	}, []);

	const handleImageChange = (e) => {
		e.preventDefault();
		let file = e.target.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = () => {
			setImgBase64(reader.result.replace(/data:.*;base64,/gi, ''));
			document.getElementById('avatar_edit_profile').innerHTML = '';
			var img = document.createElement('img');
			img.src = reader.result;
			img.className = 'img-thumbnail';
			img.style.width = '100px';
			img.style.height = '100px';
			document.getElementById('avatar_edit_profile').appendChild(img);
		};
	};

	const onSubmit = async (data) => {
		try {
			setDisableButton(true);
			if (data.avatar.length != 0) {
				data.avatar = imgBase64;
			} else {
				delete data.avatar;
			}

			if (data.dob != '') {
				if (
					!data.dob.match(
						/^((0[13578]|1[02])(\/)(0[1-9]|[12][0-9]|3[01]))|((02)(\/)(0[1-9]|[12][0-9]))|((0[469]|11)(\/)(0[1-9]|[12][0-9]|3[0]))$/
					)
				) {
					document.getElementById('dob_format').textContent =
						'Please enter Birthday the correct format';
					setDisableButton(false);
					return false;
				} else {
					data.dob = data.dob + '/2020';
					document.getElementById('dob_format').textContent = '';
				}
			}
			else {
				delete data.dob;
			}
			
			if (data.email == '') {
				delete data.email;
			}
			const response = await API.put(
				`customer/updateCustomer/${getDataCookie(
					process.env.REACT_APP_COOKIE_KEY
				)}`,
				{
					payload: data,
				}
			);
			let { code } = response.data;
			if (code === 200) {
				infor_customer.customerName = response.data.payload.name;
				infor_customer.avatar = response.data.payload.imageAvatarUrl;
				localStorage.setItem(
					'infor_customer',
					JSON.stringify(infor_customer)
				);
				document.getElementById('result_edit_profile').innerHTML =
					'Edit Profile Success';
					setDisableButton(false);
			}
		} catch (error) {
			setDisableButton(false);
			const { data } = error.response;
			document.getElementById('result_edit_profile').innerHTML = '';
			setError('saveEditProfile', data.message);
		}
	};
	const list_gender = constant.SEX_VALUE.map((item, index) => {
		var selected = '';
		if (inforCustomer && inforCustomer.gender) {
			if (inforCustomer.gender == item) {
				selected = 'selected';
			}
		}
		return (
			<option value={item} key={index} selected={selected}>
				{item}
			</option>
		);
	});

	return (
		<Container className="mt-2">
			<Helmet title="Edit Profile" />
			<Form className="form mt-2 mb-2" onSubmit={handleSubmit(onSubmit)}>
				{errors.saveEditProfile && (
					<FormFeedback>
						<h2 className="text-center" style={{ color: 'red' }}>
							Edit profile failed!
						</h2>
					</FormFeedback>
				)}
				<FormFeedback>
					<h2
						className="text-center"
						style={{ color: '#0085e7' }}
						id="result_edit_profile"
					></h2>
				</FormFeedback>
				<h3>General Information</h3>
				<div id="avatar_edit_profile"></div>
				<FormGroup className="form-customize mb-0">
					<img
						height={30}
						width={30}
						style={{ position: 'absolute', top: '3px' }}
						alt="..."
						src={require('assets/img/profile/IC_infor.png')}
					/>
					<Input
						type="file"
						name="avatar"
						id="avatar"
						className="input-edit-profile"
						innerRef={register({})}
						onChange={handleImageChange}
					/>
					<Label
						for="fullName"
						className="form-customize-label"
						style={{ left: '3.2rem' }}
					>
						Avatar
					</Label>
				</FormGroup>
				<hr style={{ marginTop: '5px', marginBottom: '20px' }} />
				<FormGroup className="form-customize mb-0">
					<img
						height={30}
						width={30}
						style={{ position: 'absolute', top: '3px' }}
						alt="..."
						src={require('assets/img/profile/IC_infor.png')}
					/>
					<Input
						type="text"
						name="fullName"
						id="fullName"
						placeholder="Full Name"
						defaultValue={inforCustomer && inforCustomer.name}
						className="input-edit-profile"
						innerRef={register({
							required: true,
						})}
					/>
					<Label
						for="fullName"
						className="form-customize-label"
						style={{ left: '3.2rem' }}
					>
						Full Name
					</Label>
				</FormGroup>
				<hr style={{ marginTop: '5px', marginBottom: '20px' }} />
				<FormGroup className="form-customize mb-0" style={{display:'flex'}}>
					<div style={{width:'40px'}}>
						<img
							height={30}
							width={30}
							style={{ position: 'absolute', top: '3px' }}
							alt="..."
							src={require('assets/img/profile/IC_gender.png')}
						/>
					</div>
					<div style={{width:'100%'}}>
						<Input
							type="select"
							name="gender"
							id="gender"
							className="form-customize-input input-edit-profile select_edit_profile"
							innerRef={register({
								required: true,
							})}
						>
							{list_gender}
						</Input>
						<Label
							for="gender"
							className="form-customize-label"
							style={{ left: '3.2rem' }}
						>
							Gender
						</Label>
					</div>
				</FormGroup>
				<hr style={{ marginTop: '5px', marginBottom: '20px' }} />
				<FormGroup className="form-customize mb-0">
					<img
						height={30}
						width={30}
						style={{ position: 'absolute', top: '3px' }}
						alt="..."
						src={require('assets/img/profile/IC_date.png')}
					/>
					<Controller
						type="text"
						as={InputMask}
						control={control}
						mask="99/99"
						className="input-edit-profile"
						placeholder="MM/DD"
						name="dob"
						defaultValue={getValues('dob')}
						// required="required"
						// rules={{
						// 	required: true,
						// }}
					/>
					<Label
						for="dob"
						className="form-customize-label"
						style={{ left: '3.2rem' }}
					>
						Date of birth
					</Label>
					<FormFeedback
						className="mt-0"
						id="dob_format"
					></FormFeedback>
				</FormGroup>
				<div className="mt-4 mb-8" id="account_infor">
					<h3>Account Information</h3>
					<FormGroup className="form-customize mb-0">
						<img
							height={30}
							width={30}
							style={{ position: 'absolute', top: '3px' }}
							alt="..."
							src={require('assets/img/profile/IC_mail.png')}
						/>
						<Input
							type="email"
							name="email"
							id="email"
							defaultValue={inforCustomer && inforCustomer.email}
							className="input-edit-profile"
							innerRef={register({
								// required: true,
							})}
							// readOnly={
							// 	inforCustomer && inforCustomer.email
							// 		? true
							// 		: false
							// }
						/>
						<Label
							for="fullName"
							className="form-customize-label"
							style={{ left: '3.2rem' }}
						>
							Email
						</Label>
					</FormGroup>
					<hr style={{ marginTop: '5px', marginBottom: '20px' }} />
					<FormGroup className="form-customize mb-0">
						<img
							height={30}
							width={30}
							style={{ position: 'absolute', top: '3px' }}
							alt="..."
							src={require('assets/img/profile/IC_Phone.png')}
						/>
						<Input
							type="text"
							name="phone"
							id="phone"
							readOnly="readOnly"
							value={inforCustomer && inforCustomer.phone}
							className="input-edit-profile"
						/>
						<Label
							for="fullName"
							className="form-customize-label"
							style={{ left: '3.2rem' }}
						>
							Phone
						</Label>
					</FormGroup>
				</div>
				<Button
					color="primary"
					type="submit"
					className="fixed-bottom btn btn-block btn-primary mt-2 mb-1"
					style={{ position: 'fixed', width: '95%', left: '10px' }}
					disabled={disableButton}
				>
					{!disableButton ? 'Save' : 'Pending...'}
				</Button>
			</Form>
		</Container>
	);
}
